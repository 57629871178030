import React from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, screenSizeAtom } from "../../atom";
import { commonStyle } from "../../components/appCommon";

import { Layout, Button, Table, Card, Select, Typography, Row, Col, Space, Dropdown, Tag } from "@douyinfe/semi-ui";

import AppHeader from "../../components/header";

const tabelscroll = commonStyle.tabelscroll;
const rowWidth = commonStyle.tableRowWidth;
const pagerSize = commonStyle.pagerSize;

export default function Product() {
  const [productList, setProductList] = React.useState([]);
  const [planList, setPlanList] = React.useState([]);
  const [perPage, setPerPage] = React.useState(10);
  const navigate = useNavigate();
  const headerRef = React.useRef();

  const { Text } = Typography;
  const { Content } = Layout;
  const { Column } = Table;

  const [screenSize, setScreenSize] = useAtom(screenSizeAtom);
  const [jwtToken] = useAtom(jwtTokenAtom);

  const fetchProductList = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/products?limit=-1", {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setProductList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchPlans = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/products?limit=-1", {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        setPlanList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    fetchPlans();
    fetchProductList();
  }, []);

  const renderPlan = (planId) => {
    return planList.find((x) => x.id === planId)?.name;
  };

  const renderUser = (userId) => {
    console.log(userId);
    return (
      <Button
        onClick={() => {
          navigate("/user/edit/" + userId.toString());
        }}
      >
        {userId}
      </Button>
    );
  };

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col span={23}>
              <Card
                title="产品列表"
                headerExtraContent={
                  <Space>
                    <Dropdown
                      render={
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setPerPage(10)}>每页 10 条</Dropdown.Item>
                          <Dropdown.Item onClick={() => setPerPage(25)}>每页 25 条</Dropdown.Item>
                          <Dropdown.Item onClick={() => setPerPage(50)}>每页 50 条</Dropdown.Item>
                        </Dropdown.Menu>
                      }
                    >
                      <Tag>每页 {perPage} 条</Tag>
                    </Dropdown>
                  </Space>
                }
              >
                <Table
                  dataSource={productList}
                  style={{}}
                  scroll={tabelscroll}
                  pagination={{
                    pageSize: perPage,
                    total: productList.length,
                    size: pagerSize(screenSize),
                    hoverShowPageSelect: true,
                  }}
                >
                  <Column title="ID" dataIndex="id" key="id" fixed width={rowWidth.short} align="center" />

                  <Column
                    title="用户"
                    dataIndex="user_id"
                    key="user"
                    render={renderUser}
                    fixed
                    width={rowWidth.short}
                    align="center"
                  />
                  <Column
                    title="创建时间"
                    dataIndex="created_at"
                    key="created_at"
                    width={rowWidth.mid}
                    align="center"
                  />
                  {/* 用户要渲染按钮到用户编辑页面 */}

                  <Column
                    title="套餐"
                    dataIndex="sort"
                    key="sort"
                    render={renderPlan}
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column title="等级" dataIndex="status" key="status" width={rowWidth.mid} align="center" />
                  <Column title="价格" dataIndex="price" key="price" width={rowWidth.mid} align="center" />
                  <Column
                    title="启用时间"
                    dataIndex="start_date"
                    key="start_date"
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="套餐周期"
                    dataIndex="billing_interval"
                    key="billing_interval"
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="过期时间"
                    dataIndex="expiry_date"
                    key="expiry_date"
                    width={rowWidth.long}
                    align="center"
                  />
                  <Column title="端口" dataIndex="port" key="port" width={rowWidth.mid} align="center" />
                  <Column title="密码" dataIndex="password" key="password" width={rowWidth.mid} align="center" />
                  <Column
                    title="总流量"
                    dataIndex="traffic_total"
                    key="traffic_total"
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="剩余流量"
                    dataIndex="traffic_remaining"
                    key="traffic_remaining"
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="操作"
                    key="operate"
                    width={rowWidth.mid}
                    align="center"
                    render={(item) => {
                      return (
                        <Button
                          onClick={() => {
                            navigate("/product/info/" + item.id);
                          }}
                        >
                          查看
                        </Button>
                      );
                    }}
                  />
                </Table>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
