import React from "react";
import { useNavigate, Link } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom } from "../../atom";

import {
  Layout,
  Button,
  Table,
  Card,
  Row,
  Col,
  Space,
} from "@douyinfe/semi-ui";

import AppHeader from "../../components/header";

export default function Staff() {
  const { Content, Footer } = Layout;
  const { Column } = Table;

  const [jwtToken] = useAtom(jwtTokenAtom);

  const [staffList, setStaffList] = React.useState([]);
  const headerRef = React.useRef();
  const navigate = useNavigate();

  const fetchStaffList = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/list?limit=-1", {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        setStaffList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    fetchStaffList();
  }, []);

  const data = staffList;

  const renderEdit = (item) => {
    return (
      // <Link to={"/staff/edit/" + item.id}>
      <Button
        onClick={() => {
          navigate("/staff/edit/" + item.id);
        }}
      >
        编辑
      </Button>
      // </Link>
    );
  };

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col span={23}>
              <Card
                title={"管理员列表"}
                headerExtraContent={<Space></Space>}
                style={{ width: "100%" }}
              >
                <Table
                  dataSource={data}
                  pagination={false}
                  style={{ backgroundColor: "white" }}
                >
                  <Column
                    title="ID"
                    dataIndex="id"
                    key="id"
                    fixed
                    width={60}
                    align="center"
                  />
                  <Column
                    title="管理员邮箱"
                    dataIndex="email"
                    key="email"
                    width={100}
                    align="center"
                  />
                  <Column
                    title="管理员角色"
                    dataIndex="role"
                    key="role"
                    width={100}
                    align="center"
                  />
                  <Column
                    title="操作"
                    key="operate"
                    render={renderEdit}
                    width={100}
                    align="center"
                  />
                </Table>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
