import React from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, screenSizeAtom } from "../../atom";

import AnnouncementAdd from "./add.js";
import AnnouncementEdit from "./edit";

import { commonStyle } from "../../components/appCommon";

import { Layout, Button, Row, Col, Table, Card, Modal, Space, Dropdown, Tag, Typography } from "@douyinfe/semi-ui";

import AppHeader from "../../components/header";

export default function Announcement() {
  const { Content, Footer } = Layout;
  const { Column } = Table;
  const { Text, Paragraph } = Typography;

  const tabelscroll = commonStyle.tabelscroll;
  const pagerSize = commonStyle.pagerSize;

  const [announcementList, setAnnouncementList] = React.useState([]);
  const [addModalVisible, setaddModalVisible] = React.useState(false);
  const [editModalVisible, seteditModalVisible] = React.useState(false);
  const [confirmModalVisible, setconfirmModalVisible] = React.useState(false);
  const [editAnnouncement, seteditAnnouncement] = React.useState({});
  const [perPage, setPerPage] = React.useState(10);
  const headerRef = React.useRef();

  const inputRef = React.useRef();
  const editRef = React.useRef();

  const [jwtToken] = useAtom(jwtTokenAtom);
  const [screenSize, setScreenSize] = useAtom(screenSizeAtom);

  const fetchAnnouncementList = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/announcements", {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setAnnouncementList(res.data.data);
      })
      .catch((issue) => {
        console.log(issue);
      });
  };

  React.useEffect(() => {
    fetchAnnouncementList();
  }, []);

  const deleteAnnouce = (id) => {
    axios
      .post(
        process.env.REACT_APP_API_ROUTE + "/api/v1/staff/announcements/" + id + "/delete",
        {},
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        fetchAnnouncementList();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const renderContent = (v) => {
    return (
      <Paragraph
        ellipsis={{
          rows: 2,
          expandable: true,
          collapsible: true,
          collapseText: "收起",
          onExpand: (bool, e) => console.log(bool, e),
        }}
      >
        {v}
      </Paragraph>
    );
  };

  const renderEdit = (item) => {
    return (
      <Row>
        <Button
          onClick={() => {
            seteditAnnouncement(item);
            seteditModalVisible(true);
          }}
        >
          编辑
        </Button>

        <Button
          type="danger"
          onClick={() => {
            seteditAnnouncement(item);
            setconfirmModalVisible(true);
          }}
        >
          删除
        </Button>
      </Row>
    );
  };

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col span={23}>
              <Card
                title="公告列表"
                headerExtraContent={
                  <Space>
                    <Dropdown
                      render={
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setPerPage(10)}>每页 10 条</Dropdown.Item>
                          <Dropdown.Item onClick={() => setPerPage(25)}>每页 25 条</Dropdown.Item>
                          <Dropdown.Item onClick={() => setPerPage(50)}>每页 50 条</Dropdown.Item>
                        </Dropdown.Menu>
                      }
                    >
                      <Tag>每页 {perPage} 条</Tag>
                    </Dropdown>
                    <Text
                      link
                      onClick={() => {
                        setaddModalVisible(true);
                      }}
                    >
                      新增
                    </Text>
                  </Space>
                }
              >
                <Table
                  dataSource={announcementList}
                  scroll={tabelscroll}
                  pagination={{
                    pageSize: perPage,
                    total: announcementList.length,
                    size: pagerSize(screenSize),
                    hoverShowPageSelect: true,
                  }}
                  style={{ backgroundColor: "white" }}
                >
                  <Column title="ID" dataIndex="id" key="id" fixed width={60} align="center" />
                  <Column
                    title="公告正文"
                    dataIndex="content"
                    key="content"
                    width={160}
                    align="center"
                    render={renderContent}
                  />
                  <Column title="操作" key="operate" render={renderEdit} width={150} align="center" />
                </Table>
              </Card>
            </Col>
          </Row>
          <Row>
            <Modal
              title="新增公告"
              visible={addModalVisible}
              style={{ width: "350px" }}
              onOk={(e) => {
                inputRef.current.submitForm();
                setaddModalVisible(false);
              }}
              afterClose={() => {
                console.log("Modal closed");
              }}
              onCancel={() => {
                setaddModalVisible(false);
              }}
              closeOnEsc={true}
            >
              <AnnouncementAdd ref={inputRef} refresh={fetchAnnouncementList}></AnnouncementAdd>
            </Modal>
          </Row>
          <Row>
            <Modal
              title={"公告编辑:" + editAnnouncement.id}
              visible={editModalVisible}
              style={{ width: "350px" }}
              onOk={(e) => {
                editRef.current.submitForm();
                seteditModalVisible(false);
              }}
              afterClose={() => {
                console.log("Modal closed");
              }}
              onCancel={() => {
                seteditModalVisible(false);
              }}
              closeOnEsc={true}
            >
              <AnnouncementEdit
                ref={editRef}
                announce={editAnnouncement}
                refresh={fetchAnnouncementList}
              ></AnnouncementEdit>
            </Modal>
          </Row>
          <Row>
            <Modal
              title={"删除确认"}
              visible={confirmModalVisible}
              style={{ width: "350px" }}
              onOk={(e) => {
                deleteAnnouce(editAnnouncement.id);
                setconfirmModalVisible(false);
              }}
              afterClose={() => {
                console.log("Modal closed");
              }}
              onCancel={() => {
                setconfirmModalVisible(false);
              }}
              closeOnEsc={true}
            >
              是否删除公告：{editAnnouncement.id}
            </Modal>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
