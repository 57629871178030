import React from "react";
import { useNavigate, useParams, Link } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom } from "../../atom";

export default function StaffEdit() {
  const [jwtToken] = useAtom(jwtTokenAtom);

  const id = useParams();

  return <div></div>;
}
