import React from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, screenSizeAtom } from "../../atom";
import { commonStyle } from "../../components/appCommon";

import {
  Layout,
  Button,
  Table,
  Card,
  Row,
  Col,
  Space,
  Dropdown,
  Tag,
  Typography,
} from "@douyinfe/semi-ui";

import AppHeader from "../../components/header";

const tabelscroll = commonStyle.tabelscroll;
const rowWidth = commonStyle.tableRowWidth;
const pagerSize = commonStyle.pagerSize;

export default function Order() {
  const [orderList, setOrderList] = React.useState([]);
  const [planList, setPlanList] = React.useState([]);
  const [perPage, setPerPage] = React.useState(10);
  const headerRef = React.useRef();
  const navigate = useNavigate();

  const { Content } = Layout;
  const { Column } = Table;
  const { Text } = Typography;

  const [jwtToken] = useAtom(jwtTokenAtom);
  const [screenSize, setScreenSize] = useAtom(screenSizeAtom);

  const fetchOrderList = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/orders?limit=-1", {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        setOrderList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchPlans = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/plans?limit=-1", {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        setPlanList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    fetchPlans();
    fetchOrderList();
  }, []);

  const renderUser = (userId) => {
    return (
      <Button
        onClick={() => {
          navigate("/user/edit/" + userId.toString());
        }}
      >
        {userId}
      </Button>
    );
  };

  const renderPlan = (planId) => {
    return planList.find((x) => x.id === planId)?.name;
  };

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col span={23}>
              <Card
                title="订单列表"
                headerExtraContent={
                  <Space>
                    <Dropdown
                      render={
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setPerPage(10)}>
                            每页 10 条
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setPerPage(25)}>
                            每页 25 条
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setPerPage(50)}>
                            每页 50 条
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      }
                    >
                      <Tag>每页 {perPage} 条</Tag>
                    </Dropdown>
                    <Text
                      link
                      onClick={() => {
                        navigate("/order/add/-1/-1");
                      }}
                    >
                      新增
                    </Text>
                  </Space>
                }
                style={{
                  margin: "20px 0px",
                }}
              >
                <Table
                  dataSource={orderList}
                  rowKey="id"
                  style={{}}
                  scroll={tabelscroll}
                  pagination={{
                    pageSize: perPage,
                    total: orderList.length,
                    size: pagerSize(screenSize),
                    hoverShowPageSelect: true,
                  }}
                >
                  <Column
                    title="订单ID"
                    dataIndex="id"
                    key="id"
                    fixed
                    width={rowWidth.short}
                    align="center"
                  />
                  <Column
                    title="用户"
                    dataIndex="user"
                    key="user"
                    render={renderUser}
                    fixed
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="创建时间"
                    dataIndex="created_at"
                    key="created_at"
                    width={rowWidth.mid}
                    align="center"
                  />
                  {/* 用户要渲染按钮到用户编辑页面 */}

                  <Column
                    title="套餐"
                    dataIndex="plan"
                    key="plan"
                    render={renderPlan}
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="状态"
                    dataIndex="status"
                    key="status"
                    width={rowWidth.mid}
                    align="center"
                    filters={[
                      {
                        text: "未支付",
                        value: 0,
                      },
                      {
                        text: "已支付",
                        value: 1,
                      },
                    ]}
                    onFilter={(value, record) => record.status === value}
                    render={(item) => {
                      return item === 0 ? "未支付" : "已支付";
                    }}
                  />
                  <Column
                    title="价格"
                    dataIndex="price"
                    key="price"
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="启用时间"
                    dataIndex="start_date"
                    key="start_date"
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="套餐周期"
                    dataIndex="billing_interval"
                    key="billing_interval"
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="过期时间"
                    dataIndex="due_date"
                    key="due_date"
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="产品ID"
                    dataIndex="product"
                    key="product"
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="订单类型"
                    dataIndex="type"
                    key="type"
                    width={rowWidth.mid}
                    align="center"
                  />
                </Table>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
