import React from "react";
import { useNavigate } from "react-router-dom";
// import { createStyles, Navbar, Group, Code, NavLink } from '@mantine/core';

import { isLoginAtom, screenSizeAtom, jwtTokenAtom } from "../atom";
import { useAtom } from "jotai";

import { Nav, Row, Col, Typography, Button, Avatar, Dropdown, Card, Space } from "@douyinfe/semi-ui";

import {
  IconHome,
  IconUserGroup,
  IconUser,
  IconHistogram,
  IconMail,
  IconCart,
  IconServer,
  IconSemiLogo,
  IconBranch,
  IconLink,
  IconMenu,
  IconExit,
  IconGift,
} from "@douyinfe/semi-icons";

const navdata = [
  { itemKey: "dashboard", text: "状态监控", icon: <IconHome /> },
  { itemKey: "staff", text: "管理人员", icon: <IconUserGroup /> },
  { itemKey: "user", text: "用户管理", icon: <IconUser /> },
  { itemKey: "plan", text: "套餐管理", icon: <IconHistogram /> },
  { itemKey: "announcement", text: "公告管理", icon: <IconMail /> },
  { itemKey: "order", text: "订单管理", icon: <IconCart /> },
  { itemKey: "product", text: "产品管理", icon: <IconServer /> },
  { itemKey: "node", text: "节点管理", icon: <IconBranch /> },
  { itemKey: "domain", text: "域名管理", icon: <IconLink /> },
  { itemKey: "log", text: "日志管理", icon: <IconServer /> },
  { itemKey: "affiliate", text: "返利管理", icon: <IconGift /> },
  { itemKey: "voucher", text: "礼券管理", icon: <IconGift /> },
];

export default function AppNavbar(props) {
  const navigate = useNavigate();

  const [islogin, setIsLogin] = useAtom(isLoginAtom);

  const [data, setdata] = React.useState([]);
  const [visibleCount, setvisibleCount] = React.useState(0);
  const [dropdownVisible, setDropdownVisible] = React.useState(false);

  const [screenSize, setScreenSize] = useAtom(screenSizeAtom);
  const [jwtToken, setJwtToken] = useAtom(jwtTokenAtom);
  const [selectedKey, setSelectedKey] = React.useState([""]);

  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    console.log(islogin);
    if (islogin) {
      setdata(navdata);
    } else {
      setdata([]);
      navigate("/auth");
      return;
    }

    switch (screenSize) {
      case "sm":
        setvisibleCount(0);
        break;
      case "md":
        setvisibleCount(3);
        break;
      case "xl":
        setvisibleCount(5);
        break;
      case "xxl":
        setvisibleCount(navdata.length);
        break;
      default:
    }

    // console.log(visibleCount, props.screenSize, data.length);
  }, [screenSize, islogin]);

  const handleLogout = () => {
    setIsLogin(false);
    setJwtToken(null);
  };

  return (
    <Card>
      <div className="grid">
        <Row type="flex" justify="center">
          <Col>
            <Row type="flex" justify="center">
              <Col>
                <Nav
                  mode={"horizontal"}
                  items={data.slice(0, visibleCount)}
                  selectedKeys={selectedKey}
                  onSelect={(item) => {
                    console.log(item.itemKey);
                    navigate("/" + item.itemKey);
                    setSelectedKey([item.itemKey]);
                  }}
                  header={{
                    logo: <IconSemiLogo style={{ height: "36px", fontSize: 36 }} />,
                    text: "b2CN STAFF",
                    link: "/",
                  }}
                  footer={
                    <Space>
                      {visibleCount >= navdata.length || !islogin ? (
                        ""
                      ) : (
                        <Dropdown
                          trigger="custom"
                          position="bottomRight"
                          visible={dropdownVisible}
                          onClickOutSide={() => {
                            setDropdownVisible(false);
                          }}
                          // clickToHide={true}
                          render={
                            <Dropdown.Menu>
                              {data.map((e, i) => {
                                if (i >= visibleCount) {
                                  return (
                                    <Nav.Item
                                      itemKey={e.itemKey}
                                      key={e.itemKey}
                                      text={e.text}
                                      icon={e.icon}
                                      onClick={() => {
                                        navigate("/" + e.itemKey);
                                        setDropdownVisible(false);
                                      }}
                                    ></Nav.Item>
                                  );
                                }
                              })}
                            </Dropdown.Menu>
                          }
                        >
                          <IconMenu
                            onClick={() => {
                              setDropdownVisible(!dropdownVisible);
                            }}
                          />
                        </Dropdown>
                      )}

                      <Button
                        theme="borderless"
                        style={{ color: `var(--semi-color-tertiary)` }}
                        icon={<IconExit />}
                        onClick={handleLogout}
                      >
                        {screenSize === "sm" ? "" : "登出"}
                      </Button>
                    </Space>
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Card>
  );
}
