import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

const tokenString = JSON.parse(window.localStorage.getItem("jwtTokenStaff"));
var isExpired;
if (tokenString) {
  const token64 = tokenString.split(" ")[1];
  const tokenPayLoad = token64.split(".")[1];
  const payloadString = atob(tokenPayLoad);
  const tokenObj = JSON.parse(payloadString);
  //   console.log("expire time = ", new Date(tokenObj.exp * 1000).toDateString());
  isExpired = Date.now() / 1000 >= tokenObj.exp;
} else {
  isExpired = true;
}

export const isLoginAtom = atom(!isExpired);
export const jwtTokenAtom = atomWithStorage("jwtTokenStaff", "");
export const screenSizeAtom = atom("sm");
