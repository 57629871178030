import React from "react";
import { Link, navigat, useNavigate } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, screenSizeAtom } from "../../atom";

import { commonStyle } from "../../components/appCommon";

import Affliateadd from "./add";

import {
  Layout,
  Button,
  Row,
  Col,
  Table,
  Card,
  Modal,
  Space,
  Dropdown,
  Tag,
  Typography,
  Descriptions,
  Form
} from "@douyinfe/semi-ui";

export default function Affiliate() {
  const { Content, Footer } = Layout;
  const { Column } = Table;
  const { Text, Paragraph } = Typography;

  const tabelscroll = commonStyle.tabelscroll;
  const rowWidth = commonStyle.tableRowWidth;
  const pagerSize = commonStyle.pagerSize;

  const navigate = useNavigate();

  const [perPage, setPerPage] = React.useState(10);
  const [addModalVisible, setaddModalVisible] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("false");
  const [infoModalVisible, setinfoModalVisible] = React.useState(false);
  const [affOnInfo, setaffOnInfo] = React.useState(false);
  const [affList, setAffList] = React.useState([]);
  const [showErr, setShowErr] = React.useState(false);

  const inputRef = React.useRef();

  const [jwtToken] = useAtom(jwtTokenAtom);
  const [screenSize, setScreenSize] = useAtom(screenSizeAtom);


  const api = React.useRef();

  const submitAdd = (values)=>{
    axios
      .post(
        process.env.REACT_APP_API_ROUTE + "/api/v1/staff/affiliates/create",
        { affiliate: values },
        {
          headers: {
            Authorization: jwtToken,
            // "Content-Type": "application/json",
            // processData: "false",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          console.log(res.data);
          
          // props.refresh();
          setaddModalVisible(false)
          fetchAffiliateList()
        }else{
          setErrorMessage(res.data.message)
          setShowErr(true)
        }
      });
  }

  const inputList = (
    <div className="grid">
      <Row type={"flex"} justify={"center"}>
        <Col span={24}>
          <Form
            style={{ width: "100%" }}
            onSubmit={submitAdd}
            getFormApi={(formApi) => (api.current = formApi)}
          >
            <Form.InputNumber
              field="user"
              label="用户ID"
              hideButtons
              rules={[{ required: true, message: "必须填写用户ID" }]}
              style={{ width: "100%" }}
            />
            <Row type={"flex"} justify={"space-between"}>
              <Col span={10}>
                <Form.Select
                  field="mode"
                  label="模式"
                  placeholder="请选择模式"
                  rules={[{ required: true, message: "必须选择模式" }]}
                  style={{ width: "100%" }}
                >
                  <Form.Select.Option value={0}>首单 </Form.Select.Option>
                  <Form.Select.Option value={1}>循环 </Form.Select.Option>
                </Form.Select>
              </Col>
              <Col span={10}>
                <Form.Select
                  field="status"
                  label="状态"
                  placeholder="请选择状态"
                  rules={[{ required: true, message: "必须选择状态" }]}
                  style={{ width: "100%" }}
                >
                  <Form.Select.Option value={1}>已激活 </Form.Select.Option>
                  <Form.Select.Option value={0}>已暂停</Form.Select.Option>
                </Form.Select>
              </Col>
            </Row>

            <Form.InputNumber
              field="value"
              label="返利比率"
              hideButtons
              rules={[{ required: true, message: "必须填写返利比率" }]}
              suffix={"%"}
              style={{ width: "100%" }}
            />

            <Form.Input
              field="code"
              label={"返利代码"}
              rules={[{ required: true, message: "必须填写返利代码" }]}
              style={{ width: "100%" }}
             
            ></Form.Input>

            {
              showErr ?
              <Row type={"flex"} justify={"center"} style={{"color":"red"}}>
                {errorMessage}
              </Row>:
              <></>
            }

            <Form.Input
              field="comment"
              label="备注"
              style={{ width: "100%" }}
              rules={[]}
            ></Form.Input>
          </Form>
        </Col>
      </Row>
    </div>
  )

  const fetchAffiliateList = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/affiliates", {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        if (res.data.code === 0) {
          setAffList(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((issue) => {
        console.log(issue);
      });
  };

  React.useEffect(() => {
    fetchAffiliateList();
  }, []);

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col span={23}>
              <Row>
                <Card
                  title="公告列表"
                  headerExtraContent={
                    <Space>
                      <Dropdown
                        render={
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setPerPage(10)}>
                              每页 10 条
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setPerPage(25)}>
                              每页 25 条
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setPerPage(50)}>
                              每页 50 条
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        }
                      >
                        <Tag>每页 {perPage} 条</Tag>
                      </Dropdown>
                      <Text
                        link
                        onClick={() => {
                          setaddModalVisible(true);
                        }}
                      >
                        新增
                      </Text>
                    </Space>
                  }
                >
                  <Table
                    dataSource={affList}
                    scroll={tabelscroll}
                    pagination={{
                      pageSize: perPage,
                      total: affList.length,
                      size: pagerSize(screenSize),
                      hoverShowPageSelect: true,
                    }}
                    rowKey="id"
                  >
                    <Column
                      title="用户ID"
                      dataIndex="user"
                      key="user"
                      width={rowWidth.short}
                      fixed
                      align="center"
                      sorter={(a, b) => (a.name > b.name ? 1 : -1)}
                    />
                    <Column
                      title="返利ID"
                      dataIndex="id"
                      key="id"
                      align="center"
                      width={rowWidth.short}
                    />

                    <Column
                      title="状态"
                      dataIndex="status"
                      key="level"
                      filters={[
                        {
                          text: "已激活",
                          value: 1,
                        },
                        {
                          text: "已暂停",
                          value: 0,
                        },
                      ]}
                      width={rowWidth.mid}
                      align="center"
                      onFilter={(value, record) => record.status === value}
                      render={(v) => (v === 1 ? "已激活" : "已暂停")}
                    />

                    <Column
                      title="返利比率"
                      dataIndex="value"
                      key="value"
                      width={rowWidth.mid}
                      align="center"
                      render={(v) => {
                        return v + "%";
                      }}
                    />
                    <Column
                      title="模式"
                      dataIndex="mode"
                      key="mode"
                      width={rowWidth.mid}
                      align="center"
                      filters={[
                        {
                          text: "循环",
                          value: 1,
                        },
                        {
                          text: "首单",
                          value: 0,
                        },
                      ]}
                      onFilter={(value, record) => record.mode === value}
                      render={(v) => {
                        return v === 0 ? "首单" : "循环";
                      }}
                    />
                    <Column
                      title="返利代码"
                      dataIndex="code"
                      key="code"
                      width={rowWidth.mid}
                      align="center"
                    />
                    <Column
                      title="备注"
                      dataIndex="comment"
                      key="comment"
                      width={rowWidth.mid}
                      align="center"
                    />
                    <Column
                      title="操作"
                      key="operate"
                      width={rowWidth.mid}
                      align="center"
                      render={(v) => {
                        return (
                          <Button
                            onClick={() => {
                              // setaffOnInfo(v);
                              // setinfoModalVisible(true);

                              navigate("/affiliate/info/" + v.id);
                            }}
                          >
                            查看
                          </Button>
                        );
                      }}
                    />
                  </Table>
                </Card>
              </Row>
              <Row>
                <Modal
                  title="新增返利"
                  visible={addModalVisible}
                  style={{ width: "350px" }}
                  onOk={(e) => {
                    api.current.submitForm();
                  }}
                  afterClose={() => {
                    console.log("Modal closed");
                  }}
                  onCancel={() => {
                    setaddModalVisible(false);
                  }}
                  closeOnEsc={true}
                >
                  {inputList}

                </Modal>
              </Row>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
