import React from "react";

import { screenSizeAtom } from "../atom";
import { useAtom } from "jotai";

import { Row, Col, Typography } from "@douyinfe/semi-ui";

export function deleteNullPorp(zodRes, obj) {
  for (let issue of zodRes.error.issues) {
    if (issue.received === "null") {
      const propsName = issue.path[0];
      Reflect.deleteProperty(obj, propsName);
    }
  }

  return obj;
}

export const commonStyle = {
  tabelscroll: {},
  tableRowWidth: {
    short: 60,
    mid: 90,
    long: 120,
  },

  pagerSize: (screenSize) =>
    screenSize === "sm" || screenSize == "" ? "small" : "default",
};

export const Empty = () => {};

export const infoRow = (items, screenSize) => {
  const mobile = (items) => {
    return (
      <Row type={"flex"} justify={"space-between"} style={{ width: "100%" }}>
        {items.map((v, i) => {
          return (
            <Col key={i} span={10}>
              <Row type={"flex"} justify={"space-between"}>
                <Col>
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "12px" }}
                  >
                    {v.text}
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text strong style={{ fontSize: "12px" }}>
                    {v.value}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    );
  };

  const PC = (items) => {
    return (
      <Row type={"flex"} justify={"start"} style={{ width: "100%" }}>
        {items.map((v, i) => {
          return (
            <Col key={i} span={9} offset={2}>
              <Row type={"flex"} justify={"space-between"}>
                <Col>
                  <Typography.Text type="secondary">{v.text}</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text strong>{v.value}</Typography.Text>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    );
  };

  return screenSize === "sm" ? mobile(items) : PC(items);
};
