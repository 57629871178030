import React from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, screenSizeAtom } from "../../atom";

import { commonStyle } from "../../components/appCommon";

import {
  Layout,
  Button,
  Table,
  Card,
  Row,
  Col,
  Space,
  Dropdown,
  Typography,
  Modal,
  Tag,
} from "@douyinfe/semi-ui";

import AppHeader from "../../components/header";
import PlanAdd from "./add";
import PlanEdit from "./edit";

const tabelscroll = commonStyle.tabelscroll;
const rowWidth = commonStyle.tableRowWidth;
const pagerSize = commonStyle.pagerSize;

export default function Plan() {
  const [addModalVisible, setaddModalVisible] = React.useState(false);
  const [editModalVisible, seteditModalVisible] = React.useState(false);
  const [confirmModalVisible, setconfirmModalVisible] = React.useState(false);
  const [editPlan, seteditPlan] = React.useState({});
  const [planList, setPlanList] = React.useState([]);
  const [perPage, setPerPage] = React.useState(10);
  const inputRef = React.useRef();
  const editRef = React.useRef();
  const headerRef = React.useRef();

  const { Content } = Layout;
  const { Column } = Table;
  const { Text } = Typography;

  const [jwtToken] = useAtom(jwtTokenAtom);
  const [screenSize, setScreenSize] = useAtom(screenSizeAtom);

  const fetchPlanList = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/plans?limit=-1", {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setPlanList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    fetchPlanList();
  }, []);

  const deletePlan = () => {
    axios
      .post(
        process.env.REACT_APP_API_ROUTE +
          "/api/v1/staff/plans/" +
          editPlan.id +
          "/delete",
        {},
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          fetchPlanList();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const renderEdit = (item) => {
    return (
      // <Link to={"/user/edit/" + item.id}>
      <Button
        onClick={() => {
          seteditPlan(item);
          console.log(item);
          seteditModalVisible(true);
        }}
      >
        编辑
      </Button>
      // </Link>
    );
  };
  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col span={23}>
              <Card
                title={"套餐列表"}
                headerExtraContent={
                  <Space>
                    <Dropdown
                      render={
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setPerPage(10)}>
                            每页 10 条
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setPerPage(25)}>
                            每页 25 条
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setPerPage(50)}>
                            每页 50 条
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      }
                    >
                      <Tag>每页 {perPage} 条</Tag>
                    </Dropdown>
                    <Text
                      link
                      onClick={() => {
                        setaddModalVisible(true);
                      }}
                    >
                      新增
                    </Text>
                  </Space>
                }
              >
                <Table
                  dataSource={planList}
                  scroll={tabelscroll}
                  pagination={{
                    pageSize: perPage,
                    total: planList.length,
                    size: pagerSize(screenSize),
                    hoverShowPageSelect: true,
                  }}
                  rowKey="id"
                  style={{ backgroundColor: "white" }}
                >
                  <Column
                    title="套餐名"
                    dataIndex="name"
                    key="name"
                    fixed
                    align="center"
                    width={rowWidth.mid}
                  />
                  <Column
                    title="ID"
                    dataIndex="id"
                    key="id"
                    width={rowWidth.short}
                  />

                  <Column
                    title="状态"
                    dataIndex="hidden"
                    filters={[
                      {
                        text: "正常",
                        value: false,
                      },
                      {
                        text: "隐藏",
                        value: true,
                      },
                    ]}
                    key="hidden"
                    render={(hidden) => (!hidden ? "正常" : "隐藏")}
                    onFilter={(value, record) => record.hidden === value}
                    align="center"
                    width={rowWidth.mid}
                  />
                  <Column
                    title="等级"
                    dataIndex="level"
                    key="level"
                    align="center"
                    width={rowWidth.mid}
                  />
                  <Column
                    title="流量"
                    dataIndex="traffic"
                    key="traffic"
                    align="center"
                    width={rowWidth.mid}
                  />
                  <Column
                    title="月付价格"
                    dataIndex="monthly_price"
                    key="monthly_price"
                    align="center"
                    width={rowWidth.mid}
                  />
                  <Column
                    title="季付价格"
                    dataIndex="quarterly_price"
                    key="quarterly_price"
                    align="center"
                    width={rowWidth.mid}
                  />
                  <Column
                    title="半年付价格"
                    dataIndex="semiannually_price"
                    key="semiannually_price"
                    align="center"
                    width={rowWidth.mid}
                  />
                  <Column
                    title="年付价格"
                    dataIndex="annually_price"
                    key="annually_price"
                    align="center"
                    width={rowWidth.mid}
                  />
                  <Column
                    title="操作"
                    key="operate"
                    render={renderEdit}
                    width={rowWidth.short}
                    align="center"
                  />
                </Table>
              </Card>
            </Col>
          </Row>
          <Row>
            <Modal
              title="套餐上架"
              style={{ width: "350px" }}
              visible={addModalVisible}
              onOk={(e) => {
                inputRef.current.submitForm();
                setaddModalVisible(false);
              }}
              afterClose={() => {
                console.log("Modal closed");
              }}
              onCancel={() => {
                setaddModalVisible(false);
              }}
              closeOnEsc={true}
            >
              <PlanAdd ref={inputRef} refresh={fetchPlanList}></PlanAdd>
            </Modal>
          </Row>
          <Row>
            <Modal
              title={"套餐编辑:" + editPlan.id}
              visible={editModalVisible}
              style={{ width: "350px" }}
              onOk={(e) => {
                editRef.current.submitForm();
                seteditModalVisible(false);
              }}
              afterClose={() => {
                console.log("Modal closed");
              }}
              onCancel={() => {
                seteditModalVisible(false);
              }}
              closeOnEsc={true}
              footer={
                <div className="grid">
                  <Row type={"flex"} justify="space-between">
                    <Col>
                      <Button
                        theme="light"
                        type="danger"
                        onClick={() => {
                          setconfirmModalVisible(true);
                        }}
                      >
                        删除该套餐
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        theme="light"
                        type="tertiary"
                        onClick={() => {
                          seteditModalVisible(false);
                        }}
                      >
                        取消
                      </Button>
                      <Button
                        theme="solid"
                        type="primary"
                        onClick={(e) => {
                          editRef.current.submitForm();
                          seteditModalVisible(false);
                        }}
                      >
                        确认
                      </Button>
                    </Col>
                  </Row>
                </div>
              }
            >
              <PlanEdit
                ref={editRef}
                refresh={fetchPlanList}
                plan={editPlan}
              ></PlanEdit>
            </Modal>
          </Row>
          <Modal
            title={"请确认是否删除"}
            visible={confirmModalVisible}
            style={{ width: "350px" }}
            onOk={(e) => {
              deletePlan();
              setconfirmModalVisible(false);

              seteditModalVisible(false);
            }}
            afterClose={() => {}}
            onCancel={() => {
              setconfirmModalVisible(false);
            }}
            closeOnEsc={true}
          >
            <Row type={"flex"} justify={"center"}>
              <Text>节点名：{editPlan.name}</Text>
            </Row>
            <Row type={"flex"} justify={"center"}>
              <Text>ID:{editPlan.id}</Text>
            </Row>
          </Modal>
        </div>
      </Content>
    </Layout>
  );
}
