import React from "react";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom } from "../atom";
import axios from "axios";

// import {
//   TextInput,
//   PasswordInput,
//   Text,
//   Checkbox,
//   Paper,
//   Title,
//   Container,
//   Group,
//   Button,
// } from "@mantine/core";

import { Layout, Button, Table, Card, Row, Col, Space, Dropdown, Form, Tag } from "@douyinfe/semi-ui";

import AppHeader from "../components/header";

export default function Auth() {
  const [emailInput, setEmailInput] = React.useState("");
  const [passwordInput, setPasswordInput] = React.useState("");

  const headerRef = React.useRef();

  const [isLogin, setIsLogin] = useAtom(isLoginAtom);
  const [, setJwtToken] = useAtom(jwtTokenAtom);

  const { Content, Footer } = Layout;
  const { Column } = Table;

  const navigate = useNavigate();

  const submitLogin = (values) => {
    console.log(values);

    delete axios.defaults.headers.common["Authorization"];

    console.log(axios.defaults.headers.common["Authorization"]);
    axios
      .post(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/login", {
        staff: {
          email: values.email,
          password: values.password,
        },
      })
      .then((res) => {
        console.log(res.data);

        const code = res.data.code;

        if (code === 0) {
          let token = res.headers["authorization"];
          setJwtToken(token);
          setIsLogin(true);
          axios.defaults.headers.common["Authorization"] = token;
          // setTip("");
          console.log(token);
          navigate("/");
        } else {
          // setTip(res.data.message);
          alert(res.data.message);
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  React.useEffect(() => {
    if (isLogin) {
      navigate("/");
    }
  });

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col sm={{ span: 23 }} md={{ span: 16 }} xl={{ span: 8 }} xxl={{ span: 5 }}>
              <Card title="管理员登录">
                <Form onSubmit={submitLogin}>
                  <Row>
                    <Form.Input field="email" label="Email" placeholder="请输入管理员邮箱"></Form.Input>
                  </Row>
                  <Row>
                    <Form.Input
                      mode="password"
                      field="password"
                      label="Password"
                      placeholder="请输入管理员密码"
                    ></Form.Input>
                  </Row>
                  <br></br>
                  <Row type={"flex"} justify={"center"}>
                    <Col>
                      <Button type="primary" htmlType="submit">
                        登录
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
