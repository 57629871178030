import z from "zod";

export const planSchema = z.object({
  name: z.string(),
  description: z.string().optional(),
  hidden: z.boolean(),
  traffic: z.number(),
  level: z.number().int(),
  monthly_price: z.number(),
  quarterly_price: z.number(),
  semiannually_price: z.number(),
  annually_price: z.number(),
});

export const planEditSchema = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  hidden: z.boolean().optional(),
  traffic: z.number().optional(),
  level: z.number().int().optional(),
  monthly_price: z.number().optional(),
  quarterly_price: z.number().optional(),
  semiannually_price: z.number().optional(),
  annually_price: z.number().optional(),
});
