import React from "react";
import { useNavigate, Link } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, screenSizeAtom } from "../../atom";

import { commonStyle } from "../../components/appCommon";

import { Layout, Button, Table, Card, Row, Col, Space, Dropdown, Tag } from "@douyinfe/semi-ui";

import AppHeader from "../../components/header";

const tabelscroll = commonStyle.tabelscroll;
const rowWidth = commonStyle.tableRowWidth;
const pagerSize = commonStyle.pagerSize;

export default function LogIndex() {
  const { Content, Footer } = Layout;
  const { Column } = Table;

  const headerRef = React.useRef();

  const [logList, setLogList] = React.useState([]);
  const [perPage, setPerPage] = React.useState(10);
  const [actionKeys, setActionKeys] = React.useState([]);
  const navigate = useNavigate();

  const [logPageSize, setLogPageSize] = React.useState(5);
  const logPagination = { pageSize: logPageSize };

  const [jwtToken] = useAtom(jwtTokenAtom);
  const [screenSize, setScreenSize] = useAtom(screenSizeAtom);

  const getActionKeys = (logs) => {
    const actions = [];
    logs.forEach((element) => {
      if (!actions.includes(element.action)) {
        actions.push(element.action);
      }
    });
    console.log("all action is ", actions);
    return actions;
  };

  const fetchLogList = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/logs?limit=-1", {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        const acitonKeys = getActionKeys(res.data.data);

        setLogList(res.data.data);
        setActionKeys(acitonKeys);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    fetchLogList();
  }, []);

  const logColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: true,
      width: rowWidth.short,
      align: "center",
    },
    {
      title: "用户ID",
      dataIndex: "user",
      key: "user",
      width: rowWidth.mid,
      align: "center",
    },
    {
      title: "动作",
      dataIndex: "action",
      key: "action",
      width: rowWidth.long,
      align: "center",
      filters: actionKeys.map((key) => {
        return { text: key, value: key };
      }),
      onFilter: (value, record) => value === record.action,
    },
    {
      title: "备注",
      dataIndex: "comment",
      key: "comment",
      width: rowWidth.mid,
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "operate",
      key: "operate",
      width: rowWidth.short,
      align: "center",
      render: (text, record, index) => {
        return (
          <Button
            onClick={() => {
              navigate("/log/" + record.id);
            }}
          >
            查看
          </Button>
        );
      },
    },
  ];

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col span={23}>
              <Card
                title="日志记录"
                headerExtraContent={
                  <Space>
                    <Dropdown
                      render={
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setPerPage(10)}>每页 10 条</Dropdown.Item>
                          <Dropdown.Item onClick={() => setPerPage(25)}>每页 25 条</Dropdown.Item>
                          <Dropdown.Item onClick={() => setPerPage(50)}>每页 50 条</Dropdown.Item>
                        </Dropdown.Menu>
                      }
                    >
                      <Tag>每页 {perPage} 条</Tag>
                    </Dropdown>
                  </Space>
                }
              >
                <Table
                  scroll={tabelscroll}
                  columns={logColumns}
                  dataSource={logList}
                  rowKey="id"
                  pagination={{
                    pageSize: perPage,
                    size: pagerSize(screenSize),
                    hoverShowPageSelect: true,
                  }}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
