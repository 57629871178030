import React from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom } from "../atom";

import { Layout, Button, Table, Card, Row, Col } from "@douyinfe/semi-ui";

import AppHeader from "../components/header";

export default function Dashboard() {
  const { Content } = Layout;
  const [isLogin, setIsLogin] = useAtom(isLoginAtom);
  const [jwtToken, setJwtToken] = useAtom(jwtTokenAtom);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isLogin) {
      navigate("/auth");
    }
  });

  return (
    <Layout>
      <Content style={{ margin: "20px 20px 20px 20px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col span={22}>
              <Card title="状态监控">Application Here</Card>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
