import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, screenSizeAtom } from "../../atom";

import { commonStyle } from "../../components/appCommon";

import {
  Layout,
  Button,
  Table,
  Card,
  Row,
  Col,
  Tag,
  Typography,
  Modal,
  Form,
  Descriptions,
  Breadcrumb,
  Space,
  TextArea,
} from "@douyinfe/semi-ui";

import { infoRow } from "../../components/appCommon";

const pagerSize = commonStyle.pagerSize;

export default function DomainGroup() {
  const { Content, Footer } = Layout;
  const { Text } = Typography;

  const [screenSize, setScreenSize] = useAtom(screenSizeAtom);

  const { id } = useParams();

  const [domains, setDomains] = React.useState([]);
  const [group, setGroup] = React.useState({});

  const [addDomainModalVisible, setAddDomainModalVisible] =
    React.useState(false);
  const [editGroupModalVisible, setEditGroupModalVisible] =
    React.useState(false);
  const [addDomainBatchModalVisible, setAddDomainBatchModalVisible] =
    React.useState(false);
  const [confirmModalVisible, setconfirmModalVisible] = React.useState(false);

  const [addDomainDomain, setAddDomainDomain] = React.useState("");
  const [addDomainComment, setAddDomainComment] = React.useState("");
  const [addDomainBatch, setAddDomainBatch] = React.useState("");
  const [toDelete, settoDelete] = React.useState({});

  const [editGroup, setEditGroup] = React.useState({});

  const [pageSize, setPageSize] = React.useState(5);
  const pagination = {
    pageSize: pageSize,
    size: pagerSize(screenSize),
    hoverShowPageSelect: true,
  };

  const domainColumns = [
    {
      title: "域名",
      dataIndex: "domain",
    },
    {
      title: "备注",
      dataIndex: "comment",
    },
    {
      title: "操作",
      dataIndex: "action",
      render: (text, record, index) => {
        return (
          <Button
            onClick={() => {
              settoDelete(record);
              setconfirmModalVisible(true);
            }}
          >
            删除
          </Button>
        );
      },
    },
  ];

  const fetchDomainGroup = () => {
    axios
      .get(
        process.env.REACT_APP_API_ROUTE + "/api/v1/staff/domains/group/" + id
      )
      .then((res) => {
        if (res.data.code === 0) {
          console.log(res.data.data.domains);
          console.log(res.data.data.group);
          setDomains(res.data.data.domains);
          setGroup(res.data.data.group);
        }
      });
  };

  const submitDomainRemove = (domainID) => {
    axios
      .post(
        process.env.REACT_APP_API_ROUTE +
          "/api/v1/staff/domains/remove/" +
          domainID
      )
      .then((res) => {
        if (res.data.code === 0) {
          fetchDomainGroup();
        }
      });
  };

  const submitDomainAdd = () => {
    axios
      .post(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/domains/add", {
        domain: {
          domain: addDomainDomain,
          comment: addDomainComment,
          sort: 2,
          group: id,
        },
      })
      .then((res) => {
        if (res.data.code === 0) {
          fetchDomainGroup();
          setAddDomainDomain("");
          setAddDomainComment("");
        }
      });
  };

  const submitDomainAddBatch = () => {
    axios
      .post(
        process.env.REACT_APP_API_ROUTE +
          "/api/v1/staff/domains/group/" +
          id +
          "/add/batch",
        {
          domain_list: addDomainBatch.split(/[\n,]/g),
        }
      )
      .then((res) => {
        if (res.data.code === 0) {
          fetchDomainGroup();
          setAddDomainBatch("");
        }
      });
  };

  const submitGroupEdit = () => {
    axios
      .post(
        process.env.REACT_APP_API_ROUTE +
          "/api/v1/staff/domains/group/" +
          id +
          "/edit",
        {
          domain_group: editGroup,
        }
      )
      .then((res) => {
        fetchDomainGroup();
      });
  };

  React.useEffect(() => {
    fetchDomainGroup();
  }, []);

  return (
    <Layout>
      <Content style={{ margin: "20px 20px 20px 20px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col span={22}>
              <Breadcrumb>
                <Breadcrumb.Item href={"/domain"}>域名管理</Breadcrumb.Item>
                <Breadcrumb.Item>自定义域名组管理</Breadcrumb.Item>
              </Breadcrumb>
              <Space></Space>
              <Card
                title={"自定义域名组 - " + group.name + " - 基本信息"}
                headerExtraContent={
                  <Text
                    link
                    onClick={() => {
                      setEditGroupModalVisible(true);
                    }}
                  >
                    编辑
                  </Text>
                }
              >
                {/* <Descriptions
                  data={[
                    { key: "域名组KEY", value: group.key },
                    { key: "域名组名", value: group.name },
                    { key: "域名组英文名", value: group.name_en },
                    { key: "域名组拼音", value: group.name_pinyin },
                    { key: "域名组类型", value: group.tag },
                    { key: "域名组LOGO", value: group.logo_url },
                  ]}
                  row
                /> */}
                <Space vertical style={{ width: "100%" }}>
                  {infoRow(
                    [
                      { text: "域名组KEY", value: group.key },
                      { text: "域名组名", value: group.name },
                    ],
                    screenSize
                  )}
                  {infoRow(
                    [
                      { text: "域名组英文名", value: group.name_en },
                      { text: "域名组拼音", value: group.name_pinyin },
                    ],
                    screenSize
                  )}
                  {infoRow(
                    [
                      { text: "域名组类型", value: group.tag },
                      { text: "域名组LOGO", value: group.logo_url },
                    ],
                    screenSize
                  )}
                </Space>
              </Card>
              <Card
                title={"自定义域名组 - " + group.name + " - 域名列表"}
                headerExtraContent={
                  <Space>
                    <Text
                      link
                      onClick={() => {
                        setAddDomainBatchModalVisible(true);
                      }}
                    >
                      批量新增
                    </Text>
                    <Text
                      link
                      onClick={() => {
                        setAddDomainModalVisible(true);
                      }}
                    >
                      新增
                    </Text>
                  </Space>
                }
                style={{ margin: "16px 0" }}
              >
                <Table
                  columns={domainColumns}
                  dataSource={domains}
                  pagination={pagination}
                />
              </Card>
              <Modal
                title="编辑自定义域名组信息"
                visible={editGroupModalVisible}
                style={{ width: "350px" }}
                onOk={() => {
                  setEditGroupModalVisible(false);
                  submitGroupEdit();
                }}
                afterClose={() => {
                  console.log("Modal closed");
                }} //>=1.16.0
                onCancel={() => {
                  setEditGroupModalVisible(false);
                  setEditGroup({});
                }}
                closeOnEsc={true}
              >
                <br />
                <Form layout="vertical">
                  <Form.Input
                    field="domain"
                    label="域名组KEY"
                    initValue={group.key}
                    onChange={(values) => {
                      let editGroupObject = JSON.parse(
                        JSON.stringify(editGroup)
                      );
                      editGroupObject.key = values;
                      setEditGroup(editGroupObject);
                    }}
                  />
                  <Form.Input
                    field="domain"
                    label="域名组名"
                    initValue={group.name}
                    onChange={(values) => {
                      let editGroupObject = JSON.parse(
                        JSON.stringify(editGroup)
                      );
                      editGroupObject.name = values;
                      setEditGroup(editGroupObject);
                    }}
                  />
                  <Form.Input
                    field="comment"
                    label="域名组英文名"
                    initValue={group.name_en}
                    onChange={(values) => {
                      let editGroupObject = JSON.parse(
                        JSON.stringify(editGroup)
                      );
                      editGroupObject.name_en = values;
                      setEditGroup(editGroupObject);
                    }}
                  />
                  <Form.Input
                    field="domain"
                    label="域名组拼音"
                    initValue={group.name_pinyin}
                    onChange={(values) => {
                      let editGroupObject = JSON.parse(
                        JSON.stringify(editGroup)
                      );
                      editGroupObject.name_pinyin = values;
                      setEditGroup(editGroupObject);
                    }}
                  />
                  <Form.Input
                    field="comment"
                    label="域名组类型"
                    initValue={group.tag}
                    onChange={(values) => {
                      let editGroupObject = JSON.parse(
                        JSON.stringify(editGroup)
                      );
                      editGroupObject.tag = values;
                      setEditGroup(editGroupObject);
                    }}
                  />
                  <Form.Input
                    field="comment"
                    label="域名组LOGO"
                    initValue={group.logo_url}
                    onChange={(values) => {
                      let editGroupObject = JSON.parse(
                        JSON.stringify(editGroup)
                      );
                      editGroupObject.logo_url = values;
                      setEditGroup(editGroupObject);
                    }}
                  />
                </Form>
              </Modal>
              <Modal
                title="新增自定义域名组域名"
                visible={addDomainModalVisible}
                style={{ width: "350px" }}
                onOk={() => {
                  setAddDomainModalVisible(false);
                  submitDomainAdd();
                }}
                afterClose={() => {
                  console.log("Modal closed");
                }} //>=1.16.0
                onCancel={() => {
                  setAddDomainModalVisible(false);
                  setAddDomainDomain("");
                  setAddDomainComment("");
                }}
                closeOnEsc={true}
              >
                创建新 自定义域名组 域名
                <br />
                <Form layout="vertical">
                  <Form.Input
                    field="domain"
                    label="域名"
                    onChange={(values) => setAddDomainDomain(values)}
                  />
                  <Form.Input
                    field="comment"
                    label="备注"
                    onChange={(values) => setAddDomainComment(values)}
                  />
                </Form>
              </Modal>
              <Modal
                title="批量新增自定义域名组域名"
                visible={addDomainBatchModalVisible}
                style={{ width: "350px" }}
                onOk={() => {
                  setAddDomainBatchModalVisible(false);
                  submitDomainAddBatch();
                }}
                afterClose={() => {
                  console.log("Modal closed");
                }} //>=1.16.0
                onCancel={() => {
                  setAddDomainBatchModalVisible(false);
                  setAddDomainBatch("");
                }}
                closeOnEsc={true}
              >
                <TextArea
                  autosize
                  rows={1}
                  onChange={(value) => setAddDomainBatch(value)}
                />
              </Modal>
              <Modal
                title={"请确认是否删除"}
                visible={confirmModalVisible}
                style={{ width: "350px" }}
                onOk={() => {
                  submitDomainRemove(toDelete.id);
                  setconfirmModalVisible(false);
                }}
                afterClose={() => {}}
                onCancel={() => {
                  setconfirmModalVisible(false);
                }}
                closeOnEsc={true}
              >
                <Row type={"flex"} justify={"center"}>
                  <Text>域名：{toDelete.domain}</Text>
                </Row>
                <Row type={"flex"} justify={"center"}>
                  <Text>ID: {toDelete.id}</Text>
                </Row>
              </Modal>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
