import React from "react";
import { useNavigate, Link, useParams } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom } from "../../atom";

import {
  Layout,
  Button,
  Table,
  Card,
  Row,
  Col,
  Descriptions,
  Typography,
} from "@douyinfe/semi-ui";


export default function LogInfo() {
  const { Content, Footer } = Layout;
  const { Column } = Table;
  const { Paragraph, Title, Text } = Typography;

  const headerRef = React.useRef();

  const [log, setLog] = React.useState({});
  const navigate = useNavigate();

  const [jwtToken] = useAtom(jwtTokenAtom);

  const { id } = useParams();

  const fetchLog = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/logs/" + id, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        setLog(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  var payloadData = [];
  var renewalData = [];
  var transactionData = [];

  function IAPCallback() {
    if (log.callback_data != null) {
      let signedPayload = log.callback_data.split(".")[1];
      let payload = JSON.parse(atob(signedPayload));
      let signedRenewal = payload.data.signedRenewalInfo.split(".")[1];
      let renewal = JSON.parse(atob(signedRenewal));
      let signedTransaction = payload.data.signedTransactionInfo.split(".")[1];
      let transaction = JSON.parse(atob(signedTransaction));
      const payloadKeys = Object.keys(payload);
      const renewalKeys = Object.keys(renewal);
      const transactionKeys = Object.keys(transaction);
      payloadKeys.forEach((key) => {
        if (key === "data") {
          Object.keys(payload[key]).forEach((dataKey) => {
            if (
              dataKey !== "signedRenewalInfo" &&
              dataKey !== "signedTransactionInfo"
            ) {
              payloadData.push({ key: dataKey, value: payload[key][dataKey] });
            }
          });
          return;
        }
        payloadData.push({ key: key, value: payload[key] });
      });
      renewalKeys.forEach((key) => {
        renewalData.push({ key: key, value: renewal[key] });
      });
      transactionKeys.forEach((key) => {
        transactionData.push({ key: key, value: transaction[key] });
      });
      return (
        <Card>
          <Row type={"flex"} justify={"center"}>
            <Col span={8}>
              <h3>Payload 数据</h3>
              <Descriptions data={payloadData} />
            </Col>
            <Col span={8}>
              <h3>Renewal 数据</h3>
              <Descriptions data={renewalData} />
            </Col>
            <Col span={8}>
              <h3>Transaction 数据</h3>
              <Descriptions data={transactionData} />
            </Col>
          </Row>
        </Card>
      );
    } else {
      return null;
    }
  }

  React.useEffect(() => {
    fetchLog();
  }, []);

  const data = [
    { key: "用户ID", value: log.user },
    { key: "动作", value: log.action },
    { key: "User-Agent", value: log.ua },
    { key: "备注", value: log.comment },
    {
      key: "回调数据",
      value: (
        <span>
          <Button>App Store 回调</Button>
        </span>
      ),
    },
  ];

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col span={23}>
              <Card title="日志记录 - 详情">
                <Descriptions data={data} row />
              </Card>
              <br />
              <IAPCallback />
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
