import React from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, screenSizeAtom } from "../../atom";

import {
  Layout,
  Row,
  Col,
  Space,
  Dropdown,
  Tag,
  Table,
  Card,
  Typography,
  Modal,
  Button,
} from "@douyinfe/semi-ui";

import AppHeader from "../../components/header";
import NodeAdd from "./add";
import NodeEdit from "./edit";
import { sortText } from "./common";
import { commonStyle } from "../../components/appCommon";

const tabelscroll = commonStyle.tabelscroll;
const rowWidth = commonStyle.tableRowWidth;
const pagerSize = commonStyle.pagerSize;

export default function Node() {
  const { Content, Footer } = Layout;
  const { Column } = Table;
  const { Text } = Typography;
  const addRef = React.useRef();
  const editRef = React.useRef();
  const headerRef = React.useRef();

  const [addModalVisible, setaddModalVisible] = React.useState(false);
  const [editModalVisible, seteditModalVisible] = React.useState(false);
  const [confirmModalVisible, setconfirmModalVisible] = React.useState(false);
  const [editNode, seteditNode] = React.useState({});
  const [nodeList, setNodeList] = React.useState([]);
  const [perPage, setPerPage] = React.useState(10);

  const [jwtToken] = useAtom(jwtTokenAtom);
  const [screenSize, setScreenSize] = useAtom(screenSizeAtom);

  const fetchNodeList = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/nodes", {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setNodeList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    fetchNodeList();
  }, []);

  const renderEdit = (n) => {
    return (
      <Button
        onClick={() => {
          seteditNode(n);
          seteditModalVisible(true);
        }}
      >
        编辑
      </Button>
    );
  };

  // const scroll = React.useMemo(() => ({ y: 300 }), []);

  const deleteNode = () => {
    axios
      .post(
        process.env.REACT_APP_API_ROUTE +
          "/api/v1/staff/nodes/" +
          editNode.id +
          "/delete"
      )
      .then((res) => {
        console.log(res);
        if (res.data.status == 0) {
          fetchNodeList();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col span={23}>
              <Card
                title="节点列表"
                headerExtraContent={
                  <Space>
                    <Dropdown
                      render={
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setPerPage(10)}>
                            每页 10 条
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setPerPage(25)}>
                            每页 25 条
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setPerPage(50)}>
                            每页 50 条
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      }
                    >
                      <Tag>每页 {perPage} 条</Tag>
                    </Dropdown>
                    <Text
                      link
                      onClick={() => {
                        setaddModalVisible(true);
                      }}
                    >
                      新增
                    </Text>
                  </Space>
                }
              >
                <Table
                  dataSource={nodeList}
                  scroll={tabelscroll}
                  pagination={{
                    pageSize: perPage,
                    total: nodeList.length,
                    size: pagerSize(screenSize),
                    hoverShowPageSelect: true,
                  }}
                >
                  <Column
                    title="节点名"
                    dataIndex="name"
                    key="name"
                    width={rowWidth.short}
                    fixed
                    align="center"
                    sorter={(a, b) => (a.name > b.name ? 1 : -1)}
                  />
                  <Column
                    title="ID"
                    dataIndex="id"
                    key="id"
                    align="center"
                    width={rowWidth.short}
                  />

                  <Column
                    title="等级"
                    dataIndex="level"
                    key="level"
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="类型"
                    key="sort"
                    render={(item) => {
                      return sortText[item.sort];
                    }}
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="地址"
                    dataIndex="address"
                    key="address"
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="端口"
                    dataIndex="port"
                    key="port"
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="加密方式"
                    dataIndex="cipher"
                    key="cipher"
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="城市"
                    dataIndex="city"
                    key="city"
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="中转"
                    key="relay"
                    render={(node) => (node.relay ? "是" : "否")}
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="中转城市"
                    dataIndex="relay_country"
                    key="relay_country"
                    width={rowWidth.mid}
                    align="center"
                  />
                  <Column
                    title="操作"
                    key="operate"
                    render={renderEdit}
                    width={rowWidth.mid}
                    align="center"
                  />
                </Table>
              </Card>
            </Col>
          </Row>
          <Row>
            <Modal
              title="新增节点"
              visible={addModalVisible}
              style={{ width: "350px" }}
              onOk={(e) => {
                addRef.current.submitForm();
                setaddModalVisible(false);
              }}
              afterClose={() => {
                console.log("Modal closed");
              }}
              onCancel={() => {
                setaddModalVisible(false);
              }}
              closeOnEsc={true}
            >
              <NodeAdd ref={addRef} refresh={fetchNodeList}></NodeAdd>
            </Modal>
          </Row>
          <Row>
            <Modal
              style={{ width: "350px" }}
              title={"编辑节点：" + editNode.id}
              visible={editModalVisible}
              onOk={(e) => {
                editRef.current.submitForm();
                seteditModalVisible(false);
              }}
              afterClose={() => {
                console.log("Modal closed");
              }}
              onCancel={() => {
                seteditModalVisible(false);
              }}
              closeOnEsc={true}
              footer={
                <div className="grid">
                  <Row type={"flex"} justify="space-between">
                    <Col>
                      <Button
                        theme="light"
                        type="danger"
                        onClick={() => {
                          setconfirmModalVisible(true);
                        }}
                      >
                        删除该节点
                      </Button>
                    </Col>

                    <Col>
                      <Button
                        theme="light"
                        type="tertiary"
                        onClick={() => {
                          seteditModalVisible(false);
                        }}
                      >
                        取消
                      </Button>
                      <Button
                        theme="solid"
                        type="primary"
                        onClick={(e) => {
                          editRef.current.submitForm();
                          seteditModalVisible(false);
                        }}
                      >
                        确认
                      </Button>
                    </Col>
                  </Row>
                </div>
              }
            >
              <NodeEdit
                ref={editRef}
                node={editNode}
                refresh={fetchNodeList}
              ></NodeEdit>
            </Modal>
          </Row>
          <Modal
            title={"请确认是否删除"}
            visible={confirmModalVisible}
            style={{ width: "350px" }}
            onOk={(e) => {
              deleteNode();
              setconfirmModalVisible(false);

              seteditModalVisible(false);
            }}
            afterClose={() => {}}
            onCancel={() => {
              setconfirmModalVisible(false);
            }}
            closeOnEsc={true}
          >
            <Row type={"flex"} justify={"center"}>
              <Text>节点名：{editNode.name}</Text>
            </Row>
            <Row type={"flex"} justify={"center"}>
              <Text>ID:{editNode.id}</Text>
            </Row>
          </Modal>
        </div>
      </Content>
    </Layout>
  );
}
