import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import axios from "axios";

const jwt = localStorage.getItem("jwtTokenStaff");

if (jwt) {
  axios.defaults.headers.common["Authorization"] = JSON.parse(jwt);
}

// const jwtString = jwt.split(" ")[1];

// const payload = jwtString.split(".")[1];

// const payloadObj = JSON.parse(atob(payload));

// if (payloadObj.exp < Date.now()) {
//   localStorage.setItem("isLoginStaff", "false");
// }

ReactDOM.render(<App />, document.getElementById("root"));
