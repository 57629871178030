import z from "zod";

export const sortText = {
  1: "http",
  2: "socks5",
  3: "shadowsocks ",
};
export const nodeSchema = z.object({
  name: z.string(),
  level: z.number().int(),
  sort: z.number().int().min(1).max(3),
  address: z.string().regex(
    /^(?:(?:\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(?:\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])$/ //ipv4
  ),
  port: z.number().nonnegative().max(65535).optional(),
  cipher: z.string(),
  city: z.string(),
  relay: z.boolean().default(false),
  relay_country: z.string().optional(),
});
