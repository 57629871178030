import React, { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout } from "@douyinfe/semi-ui";

import Dashboard from "./pages/dashboard";
import Auth from "./pages/auth";
import Staff from "./pages/staff";
import StaffEdit from "./pages/staff/edit";
import Plan from "./pages/plan";
import PlanAdd from "./pages/plan/add";
import PlanEdit from "./pages/plan/edit";
import Announcement from "./pages/announcement";
import AnnouncementAdd from "./pages/announcement/add";
import User from "./pages/user";
import UserEdit from "./pages/user/edit";
import Order from "./pages/order";
import OrderAdd from "./pages/order/add";
import Product from "./pages/product";
import ProductInfo from "./pages/product/info";
import Voucher from "./pages/voucher";

import Node from "./pages/node/index";
import NodeAdd from "./pages/node/add";

import DomainIndex from "./pages/domain/index";
import DomainGroup from "./pages/domain/group";

import LogIndex from "./pages/log/index";
import LogInfo from "./pages/log/info";

import Affiliate from "./pages/affiliates";
import AffiliateInfo from "./pages/affiliates/info";

import { screenSizeAtom } from "./atom";
import { useAtom } from "jotai";

// import AppNavbar from "./components/navbar";
import AppNavbar from "./components/navbar";

function App() {
  const { Sider, Header } = Layout;
  const [navCollapse, setnavCollapse] = React.useState(false);
  // const [screenSize, setscreenSize] = React.useState("sm");

  const [screenSize, setScreenSize] = useAtom(screenSizeAtom);

  const handleSider = (screen, bool) => {
    if (bool) {
      setScreenSize(screen);
    } else {
      if (screen === "sm") {
        setScreenSize("sm");
      } else if (screen === "md" && screenSize === "md") {
        setScreenSize("sm");
      } else if (screen === "xl" && screenSize === "xl") {
        setScreenSize("md");
      } else if (screen === "xxl" && screenSize === "xxl") {
        setScreenSize("xl");
      }
    }
  };

  return (
    <Layout style={{ height: "98vh" }}>
      <Router>
        <Header>
          <AppNavbar />
        </Header>

        <Layout style={{ backgroundColor: "#F8F8F8" }}>
          <Sider breakpoint={["sm", "md", "xl", "xxl"]} onBreakpoint={handleSider}></Sider>

          <Routes>
            <Route path={"/"} element={<Dashboard />} />
            <Route path={"/dashboard"} element={<Dashboard />} />
            <Route path={"/auth"} element={<Auth />} />

            <Route path={"/staff"} element={<Staff />} />
            <Route path={"/staff/edit/:id"} element={<StaffEdit />} />
            <Route path={"/plan"} element={<Plan />} />
            <Route path={"/plan/add"} element={<PlanAdd />} />
            <Route path={"/plan/edit/:id"} element={<PlanEdit />} />
            <Route path={"/announcement"} element={<Announcement />} />
            <Route path={"/announcement/add"} element={<AnnouncementAdd />} />
            <Route path={"/user"} element={<User />} />
            <Route path={"/user/edit/:id"} element={<UserEdit />} />
            <Route path={"/order"} element={<Order />} />
            <Route path={"/order/add/:user/:plan"} element={<OrderAdd />} />
            <Route path={"/product"} element={<Product />} />
            <Route path={"/product/info/:id"} element={<ProductInfo />} />
            <Route path={"/node"} element={<Node />} />
            <Route path={"/node/add"} element={<NodeAdd />} />
            <Route path={"/domain/group/:id"} element={<DomainGroup />} />
            <Route path={"/domain"} element={<DomainIndex />} />
            <Route path={"/log"} element={<LogIndex />} />
            <Route path={"/log/:id"} element={<LogInfo />} />
            <Route path={"/affiliate"} element={<Affiliate />} />
            <Route path={"/affiliate/info/:id"} element={<AffiliateInfo />} />
            <Route path={"/voucher"} element={<Voucher />} />
          </Routes>
        </Layout>
      </Router>
    </Layout>
  );
}

export default App;
