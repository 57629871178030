import React, { useImperativeHandle, forwardRef, useRef } from "react";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom } from "../../atom";

import z from "zod";

import { sortText } from "./common";

import { deleteNullPorp } from "../../components/appCommon";

import {
  Layout,
  Toast,
  Button,
  Form,
  Card,
  Col,
  Row,
  Input,
  useFormState,
  Typography,
} from "@douyinfe/semi-ui";

const nodeSchema = z.object({
  name: z.string().optional(),
  level: z.number().int().optional(),
  sort: z.number().int().min(1).max(3).optional(),
  info: z.string().optional(),
  address: z
    .string()
    .regex(
      /^(?:(?:\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(?:\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])$/ //ipv4
    )
    .optional(),
  port: z.number().nonnegative().max(65535).optional().optional(),
  address_public: z
    .string()
    .regex(
      /^(?:(?:\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(?:\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])$/ //ipv4
    )
    .optional(),
  port_public: z.number().nonnegative().max(65535).optional().optional(),
  cipher: z.string().optional(),
});

function NodeEdit(props, ref) {
  const api = useRef();

  const { Select } = Form;

  const [jwtToken] = useAtom(jwtTokenAtom);

  const node = props.node;

  const handleSubmit = (values) => {
    let data = values;

    console.log(data);

    let result = nodeSchema.safeParse(data);

    console.log(result);

    console.log("before if ");

    if (!result.success) {
      data = deleteNullPorp(result, data);

      result = nodeSchema.safeParse(data);
      if (!result.success) {
        console.log(result);
        return;
      }

      console.log("pass zod");
    }

    axios
      .post(
        process.env.REACT_APP_API_ROUTE +
          "/api/v1/staff/nodes/" +
          node.id +
          "/edit",
        values,
        {
          headers: {
            Authorization: jwtToken,
            "Content-Type": "multipart/form-data",
            processData: "false",
          },
        }
      )
      .then((res) => {
        if (res.data.code === 0) {
          props.refresh();
        }
      });
  };

  const submit = () => {
    api.current.submitForm();
  };

  useImperativeHandle(
    ref,
    () => {
      return { submitForm: submit };
    },
    []
  );

  return (
    <div className="grid">
      <Form
        onSubmit={(values) => handleSubmit(values)}
        getFormApi={(formApi) => (api.current = formApi)}
        // initValues={node}
      >
        <Form.Input
          field="name"
          label="节点名"
          style={{ width: "100%" }}
          placeholder={node.name}
        ></Form.Input>
        <Form.Input
          field="info"
          label="备注"
          style={{ width: "100%" }}
          placeholder={node.info}
        ></Form.Input>
        <Row type={"flex"}>
          <Col span={6}>
            <Form.InputNumber
              field="level"
              label="等级"
              style={{ width: "100%" }}
              hideButtons
              onChange={(v) => {
                api.current.setValue("level", v);
              }}
              placeholder={node.level}
            />
          </Col>
          <Col offset={5} span={13}>
            <Select
              field="sort"
              label="类型"
              style={{ width: "100%" }}
              placeholder={sortText[node.sort]}
            >
              <Select.Option value={1}>http </Select.Option>
              <Select.Option value={2}>socks5 </Select.Option>
              <Select.Option value={3}>shadowsocks </Select.Option>
            </Select>
          </Col>
        </Row>

        <Row>
          <Col span={16}>
            <Form.Input
              field="address"
              label="地址"
              style={{ width: "100%" }}
              placeholder={node.address}
            ></Form.Input>
          </Col>
          <Col offset={2} span={6}>
            <Form.InputNumber
              field="port"
              label="端口"
              style={{ width: "100%" }}
              hideButtons
              placeholder={node.port}
              onChange={(v) => {
                api.current.setValue("port", v);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={16}>
            <Form.Input
              field="address_public"
              label="公网地址"
              placeholder={node.address_public}
              style={{ width: "100%" }}
            ></Form.Input>
          </Col>
          <Col offset={2} span={6}>
            <Form.InputNumber
              field="port_public"
              label="公网端口"
              style={{ width: "100%" }}
              hideButtons
              placeholder={node.port_public}
              onChange={(v) => {
                api.current.setValue("port", v);
              }}
            />
          </Col>
        </Row>

        <Form.Input
          field="cipher"
          label="加密方式"
          style={{ width: "100%" }}
          placeholder={node.cipher}
        ></Form.Input>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        ></div>
      </Form>
    </div>
  );
}

export default forwardRef(NodeEdit);
