import React from "react";
import { useNavigate, Link } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, screenSizeAtom } from "../../atom";

import {
  Layout,
  Button,
  Table,
  Card,
  Row,
  Col,
  Space,
  Dropdown,
  Tag,
  Switch,
  Typography,
  Input,
  Modal,
} from "@douyinfe/semi-ui";

import { commonStyle } from "../../components/appCommon";

import VoucherAdd from "./add";

const statusText = {
  0: "未使用",
  1: "已兑换",
  "-1": "已撤销",
};
const fuseOption = {
  threshold: 0,
  ignoreLocation: true,
  keys: ["email"],
};

const tabelscroll = commonStyle.tabelscroll;
const rowWidth = commonStyle.tableRowWidth;

export default function Voucher() {
  const pagerSize = commonStyle.pagerSize;

  const [screenSize, setScreenSize] = useAtom(screenSizeAtom);

  const [jwtToken] = useAtom(jwtTokenAtom);

  const addRef = React.useRef();

  const navigate = useNavigate();

  const [perPage, setPerPage] = React.useState(10);

  const [planList, setPlanList] = React.useState([]);
  const [vouchersList, setVouchersList] = React.useState([]);
  const [ModalVisible, setModalVisible] = React.useState(false);

  const { Content, Footer } = Layout;
  const { Column } = Table;
  const { Text, Title } = Typography;

  const fetchVoucherList = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/vouchers")
      .then((res) => {
        console.log(res.data.data);
        setVouchersList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchPlanList = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/plans?limit=-1", {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        setPlanList(
          res.data.data.map((v) => {
            return { name: v.name, id: v.id };
          })
        );
      })
      .catch((error) => {});
  };

  React.useEffect(() => {
    fetchVoucherList();
    fetchPlanList();
  }, []);

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col span={23}>
              <Card
                title={"礼券列表"}
                headerExtraContent={
                  <Space>
                    <Text
                      link
                      onClick={() => {
                        setModalVisible(true);
                      }}
                    >
                      新增
                    </Text>
                  </Space>
                }
              >
                <Table
                  dataSource={vouchersList}
                  pagination={{
                    pageSize: perPage,
                    total: vouchersList.length,
                    size: pagerSize(screenSize),
                    hoverShowPageSelect: true,
                  }}
                  style={{ backgroundColor: "white" }}
                  scroll={tabelscroll}
                  rowKey={"id"}
                >
                  <Column title="ID" dataIndex="id" key="id" fixed width={rowWidth.short} align="center" />
                  <Column title="礼券码" dataIndex="code" key="code" width={rowWidth.long} align="center" />
                  <Column
                    title="套餐"
                    dataIndex="plan"
                    key="plan"
                    width={rowWidth.long}
                    align="center"
                    render={(id) => {
                      const res = planList.find((p) => p.id === id);
                      if (res === undefined) {
                        return "已删除";
                      } else return res.name;
                    }}
                  />
                  <Column
                    title="赠送时长"
                    dataIndex="length"
                    key="length"
                    width={rowWidth.mid}
                    align="center"
                    render={(v) => v + "天"}
                  />
                  <Column
                    title="状态"
                    dataIndex="status"
                    key="status"
                    width={rowWidth.mid}
                    align="center"
                    render={(v) => statusText[v]}
                    filters={[
                      {
                        text: "未使用",
                        value: 0,
                      },
                      {
                        text: "已兑换",
                        value: 1,
                      },
                      {
                        text: "已撤销",
                        value: "-1",
                      },
                    ]}
                    onFilter={(value, record) => record.status == value}
                  />
                  <Column
                    title="兑换时间"
                    dataIndex="redeemed_at"
                    key="redeemed_at"
                    width={rowWidth.mid}
                    align="center"
                  />

                  <Column
                    title="兑换用户"
                    dataIndex="redeemed_by"
                    key="redeemed_by"
                    width={rowWidth.mid}
                    align="center"
                    render={(userId, voucher) => {
                      if (voucher.status === 1) {
                        return (
                          <Text
                            link
                            onClick={() => {
                              navigate("/user/edit/" + userId);
                            }}
                          >
                            {userId}
                          </Text>
                        );
                      }
                    }}
                  />
                  <Column title="创建人" dataIndex="created_by" key="created_by" width={rowWidth.mid} align="center" />
                </Table>
              </Card>
            </Col>
          </Row>
          <Row>
            <Modal
              title={"新增礼券:"}
              visible={ModalVisible}
              style={{ width: "350px" }}
              onOk={(e) => {
                addRef.current.submitForm();
                setModalVisible(false);
              }}
              afterClose={() => {
                console.log("Modal closed");
              }}
              onCancel={() => {
                setModalVisible(false);
              }}
              closeOnEsc={true}
              // footer={
              //   <div className="grid">
              //     <Row type={"flex"} justify="space-between">

              //       <Col>
              //         <Button
              //           theme="light"
              //           type="tertiary"
              //           onClick={() => {
              //             setModalVisible(false);
              //           }}
              //         >
              //           取消
              //         </Button>
              //         <Button
              //           theme="solid"
              //           type="primary"
              //           onClick={(e) => {
              //             addRef.current.submitForm();
              //             setModalVisible(false);
              //           }}
              //         >
              //           确认
              //         </Button>
              //       </Col>
              //     </Row>
              //   </div>
              // }
            >
              <VoucherAdd ref={addRef} refresh={fetchVoucherList} planList={planList}></VoucherAdd>
            </Modal>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
