import React, { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, screenSizeAtom } from "../../atom";

import {
  Layout,
  Button,
  Table,
  Card,
  Row,
  Col,
  Tag,
  Typography,
  Modal,
  Form,
  Dropdown,
  Space,
} from "@douyinfe/semi-ui";

import AppHeader from "../../components/header";
import header from "../../components/header";

import { commonStyle } from "../../components/appCommon";

const tabelscroll = commonStyle.tabelscroll;
const rowWidth = commonStyle.tableRowWidth;
const pagerSize = commonStyle.pagerSize;

export default function DomainIndex() {
  const { Content, Footer } = Layout;
  const { Text } = Typography;

  const [screenSize, setScreenSize] = useAtom(screenSizeAtom);

  const [proxyDomain, setProxyDomain] = React.useState([]);
  const [directDomain, setDirectDomain] = React.useState([]);
  const [domainGroup, setDomainGroup] = React.useState([]);
  const [toDelete, settoDelete] = React.useState({});

  const domainColumns = [
    {
      title: "域名",
      width: rowWidth.mid,
      fixed: true,
      align: "center",
      dataIndex: "domain",
    },
    {
      title: "备注",
      width: rowWidth.long,
      align: "center",
      dataIndex: "comment",
    },
    {
      title: "操作",
      width: rowWidth.short,
      dataIndex: "action",
      align: "center",
      render: (text, record, index) => {
        return (
          <Button
            onClick={() => {
              settoDelete(record);
              setconfirmModalVisible(true);
            }}
          >
            删除
          </Button>
        );
      },
    },
  ];

  const groupColumns = [
    {
      title: "LOGO",
      dataIndex: "logo",
      fixed: true,
      width: rowWidth.mid,
      align: "center",
      render: (text, record, index) => {
        return (
          <img
            width={"50px"}
            height={"50px"}
            src={record.logo_url}
            alt={record.name}
          />
        );
      },
    },
    {
      title: "组名",
      dataIndex: "name",
      width: rowWidth.mid,
      align: "center",
    },
    {
      title: "标签",
      dataIndex: "tag",
      width: rowWidth.mid,
      align: "center",
    },
    {
      title: "key",
      dataIndex: "key",
      width: rowWidth.mid,
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "action",
      render: (text, record, index) => {
        return <Link to={"/domain/group/" + record.id}>编辑</Link>;
      },

      align: "center",
    },
  ];

  const [addDomainModalVisible, setAddDomainModalVisible] =
    React.useState(false);
  const [addGroupModalVisible, setAddGroupModalVisible] = React.useState(false);
  const [confirmModalVisible, setconfirmModalVisible] = React.useState(false);

  const fetchDomain = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/domains")
      .then((res) => {
        if (res.data.code === 0) {
          setProxyDomain(res.data.data.proxy);
          setDirectDomain(res.data.data.direct);
          setDomainGroup(res.data.data.groups);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const submitDomainAdd = () => {
    axios
      .post(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/domains/add", {
        domain: {
          domain: addDomainDomain,
          comment: addDomainComment,
          sort: addDomainSort,
          group: addDomainGroup,
        },
      })
      .then((res) => {
        if (res.data.code === 0) {
          fetchDomain();
          setAddDomainDomain("");
          setAddDomainComment("");
          setAddDomainGroup("");
          setAddDomainSort(0);
        }
      });
  };

  const submitGroupAdd = () => {
    axios
      .post(
        process.env.REACT_APP_API_ROUTE + "/api/v1/staff/domains/group/add",
        {
          domain_group: {
            key: addGroupKey,
            name: addGroupName,
            name_en: addGroupNameEn,
            name_pinyin: addGroupNamePinyin,
            tag: addGroupTag,
            logo_url: addGroupLogoUrl,
          },
        }
      )
      .then((res) => {
        if (res.data.code === 0) {
          fetchDomain();
          setAddGroupKey("");
          setAddGroupName("");
          setAddGroupNameEn("");
          setAddGroupNamePinyin("");
          setAddGroupTag("");
          setAddGroupLogoUrl("");
        }
      });
  };

  const submitDomainRemove = (domainID) => {
    axios
      .post(
        process.env.REACT_APP_API_ROUTE +
          "/api/v1/staff/domains/remove/" +
          domainID
      )
      .then((res) => {
        if (res.data.code === 0) {
          fetchDomain();
        }
      });
  };

  const [proxyPageSize, setProxyPageSize] = React.useState(10);
  const proxyPagination = {
    pageSize: proxyPageSize,
    size: pagerSize(screenSize),
    hoverShowPageSelect: true,
  };

  const [directPageSize, setDirectPageSize] = React.useState(10);
  const directPagination = {
    pageSize: directPageSize,
    size: pagerSize(screenSize),
    hoverShowPageSelect: true,
  };

  const [groupPageSize, setGroupPageSize] = React.useState(10);
  const groupPagination = {
    pageSize: groupPageSize,
    size: pagerSize(screenSize),
    hoverShowPageSelect: true,
  };

  const [addDomainSort, setAddDomainSort] = React.useState(0);
  const [addDomainDomain, setAddDomainDomain] = React.useState("");
  const [addDomainComment, setAddDomainComment] = React.useState("");
  const [addDomainGroup, setAddDomainGroup] = React.useState("");

  const [addGroupKey, setAddGroupKey] = React.useState("");
  const [addGroupName, setAddGroupName] = React.useState("");
  const [addGroupNameEn, setAddGroupNameEn] = React.useState("");
  const [addGroupNamePinyin, setAddGroupNamePinyin] = React.useState("");
  const [addGroupTag, setAddGroupTag] = React.useState("");
  const [addGroupLogoUrl, setAddGroupLogoUrl] = React.useState("");

  React.useEffect(() => {
    fetchDomain();
  }, []);

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"} gutter={16}>
            <Col span={23}>
              <Row type={"flex"} gutter={16}>
                <Col xl={8} xs={24}>
                  <Card
                    title={"代理域名"}
                    headerExtraContent={
                      <Space>
                        <Dropdown
                          render={
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => setProxyPageSize(10)}
                              >
                                每页 10 条
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => setProxyPageSize(25)}
                              >
                                每页 25 条
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => setProxyPageSize(50)}
                              >
                                每页 50 条
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          }
                        >
                          <Tag>每页 {proxyPageSize} 条</Tag>
                        </Dropdown>
                        <Text
                          link
                          onClick={() => {
                            setAddDomainModalVisible(true);
                            setAddDomainSort(0);
                          }}
                        >
                          新增
                        </Text>
                      </Space>
                    }
                    style={{ width: "100%" }}
                  >
                    <Table
                      scroll={tabelscroll}
                      columns={domainColumns}
                      dataSource={proxyDomain}
                      pagination={proxyPagination}
                    />
                  </Card>
                </Col>
                <Col xl={8} xs={24} style={{ margin: "0 0 16px 0" }}>
                  <Card
                    title={"直连域名"}
                    headerExtraContent={
                      <Space>
                        <Dropdown
                          render={
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => setDirectPageSize(10)}
                              >
                                每页 10 条
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => setDirectPageSize(25)}
                              >
                                每页 25 条
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => setDirectPageSize(50)}
                              >
                                每页 50 条
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          }
                        >
                          <Tag>每页 {directPageSize} 条</Tag>
                        </Dropdown>
                        <Text
                          link
                          onClick={() => {
                            setAddDomainModalVisible(true);
                            setAddDomainSort(1);
                          }}
                        >
                          新增
                        </Text>
                      </Space>
                    }
                    style={{ width: "100%" }}
                  >
                    <Table
                      scroll={tabelscroll}
                      columns={domainColumns}
                      dataSource={directDomain}
                      pagination={directPagination}
                    />
                  </Card>
                </Col>
                <Col xl={8} xs={24} style={{ margin: "0 0 16px 0" }}>
                  <Card
                    title={"自定义域名组"}
                    headerExtraContent={
                      <Space>
                        <Dropdown
                          render={
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => setGroupPageSize(10)}
                              >
                                每页 10 条
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => setGroupPageSize(25)}
                              >
                                每页 25 条
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => setGroupPageSize(50)}
                              >
                                每页 50 条
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          }
                        >
                          <Tag>每页 {groupPageSize} 条</Tag>
                        </Dropdown>
                        <Text
                          link
                          onClick={() => setAddGroupModalVisible(true)}
                        >
                          新增
                        </Text>
                      </Space>
                    }
                    style={{ width: "100%" }}
                  >
                    <Table
                      scroll={tabelscroll}
                      columns={groupColumns}
                      dataSource={domainGroup}
                      pagination={groupPagination}
                    />
                  </Card>
                </Col>
              </Row>
              <Modal
                title="新增域名"
                visible={addDomainModalVisible}
                style={{ width: "350px" }}
                onOk={() => {
                  setAddDomainModalVisible(false);
                  submitDomainAdd();
                }}
                afterClose={() => {
                  console.log("Modal closed");
                }} //>=1.16.0
                onCancel={() => {
                  setAddDomainModalVisible(false);
                  setAddDomainDomain("");
                  setAddDomainComment("");
                  setAddDomainGroup("");
                  setAddDomainSort(0);
                }}
                closeOnEsc={true}
              >
                创建新 {addDomainSort === 0 ? "代理" : "直连"} 域名
                <br />
                <Form layout="vertical">
                  <Form.Input
                    field="domain"
                    label="域名"
                    onChange={(values) => setAddDomainDomain(values)}
                  />
                  <Form.Input
                    field="comment"
                    label="备注"
                    onChange={(values) => setAddDomainComment(values)}
                  />
                </Form>
              </Modal>
              <Modal
                title="新增自定义域名组"
                visible={addGroupModalVisible}
                style={{ width: "350px" }}
                onOk={() => {
                  setAddGroupModalVisible(false);
                  submitGroupAdd();
                }}
                afterClose={() => {
                  console.log("Modal closed");
                }} //>=1.16.0
                onCancel={() => {
                  setAddGroupModalVisible(false);
                  setAddGroupKey("");
                  setAddGroupName("");
                  setAddGroupNameEn("");
                  setAddGroupNamePinyin("");
                  setAddGroupTag("");
                  setAddGroupLogoUrl("");
                }}
                closeOnEsc={true}
              >
                创建新自定义域名组
                <br />
                <Form layout="vertical">
                  <Form.Input
                    field="domain"
                    label="key"
                    onChange={(values) => setAddGroupKey(values)}
                  />
                  <Form.Input
                    field="comment"
                    label="组名"
                    onChange={(values) => setAddGroupName(values)}
                  />
                  <Form.Input
                    field="comment"
                    label="组名（英语）"
                    onChange={(values) => setAddGroupNameEn(values)}
                  />
                  <Form.Input
                    field="comment"
                    label="组名（拼音）"
                    onChange={(values) => setAddGroupNamePinyin(values)}
                  />
                  <Form.Input
                    field="comment"
                    label="标签"
                    onChange={(values) => setAddGroupTag(values)}
                  />
                  <Form.Input
                    field="comment"
                    label="Logo URL"
                    onChange={(values) => setAddGroupLogoUrl(values)}
                  />
                </Form>
              </Modal>
              <Modal
                title={"请确认是否删除"}
                visible={confirmModalVisible}
                style={{ width: "350px" }}
                onOk={(e) => {
                  submitDomainRemove(toDelete.id);
                  setconfirmModalVisible(false);
                }}
                afterClose={() => {}}
                onCancel={() => {
                  setconfirmModalVisible(false);
                }}
                closeOnEsc={true}
              >
                <Row type={"flex"} justify={"center"}>
                  <Text>域名：{toDelete.domain}</Text>
                </Row>
                <Row type={"flex"} justify={"center"}>
                  <Text>ID: {toDelete.id}</Text>
                </Row>
              </Modal>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
