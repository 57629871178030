import React from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom } from "../../atom";

import { deleteNullPorp } from "../../components/appCommon";

import {
  Layout,
  Toast,
  Button,
  Form,
  Card,
  Col,
  Row,
  useFormState,
} from "@douyinfe/semi-ui";

function VoucherAdd(props, ref) {
  const { RadioGroup, Radio, Select } = Form;

  const [jwtToken] = useAtom(jwtTokenAtom);

  const handleSubmit = (values) => {
    console.log(values);

    axios
      .post(
        process.env.REACT_APP_API_ROUTE + "/api/v1/staff/vouchers/add",
        values,
        {
          headers: {
            Authorization: jwtToken,
            "Content-Type": "multipart/form-data",
            processData: "false",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          props.refresh();
        }
      });
  };

  const api = React.useRef();

  const submit = () => {
    api.current.submitForm();
  };

  React.useImperativeHandle(
    ref,
    () => {
      return { submitForm: submit };
    },
    []
  );

  return (
    <div className="grid">
      <Row type={"flex"} justify={"center"}>
        <Col span={24}>
          <Form
            onSubmit={(values) => handleSubmit(values)}
            getFormApi={(formApi) => (api.current = formApi)}
          >
            <Row>
              <Form.Select
                field="plan"
                label="选择套餐"
                style={{ width: "100%" }}
              >
                {props.planList.map((v) => {
                  return (
                    <Form.Select.Option value={v.id}>
                      {v.name}
                    </Form.Select.Option>
                  );
                })}
              </Form.Select>
            </Row>

            <Row>
              <Form.InputNumber
                field="length"
                label="赠送天数"
                style={{ width: "100%" }}
              ></Form.InputNumber>
            </Row>

            <Row>
              <Form.Input
                field="code "
                label="礼券代码"
                style={{ width: "100%" }}
              ></Form.Input>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default React.forwardRef(VoucherAdd);
