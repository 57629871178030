import React from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom } from "../../atom";

import { Row, Col, Form } from "@douyinfe/semi-ui";

function AnnouncementAdd(props, ref) {
  // const [announcementContent, setAnnouncementContent] = React.useState("");

  const [jwtToken] = useAtom(jwtTokenAtom);
  const navigate = useNavigate();

  const submitAnnouncementAdd = (values) => {
    console.log(values);

    let announcementData = new FormData();
    announcementData.append("content", values.announcementContent);
    axios
      .post(
        process.env.REACT_APP_API_ROUTE + "/api/v1/staff/announcements/add",
        announcementData,
        {
          headers: {
            Authorization: jwtToken,
            "Content-Type": "multipart/form-data",
            processData: "false",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          console.log(res.data);
          props.refresh();
        }
      });
  };

  const api = React.useRef();

  const submit = () => {
    api.current.submitForm();
  };

  React.useImperativeHandle(
    ref,
    () => {
      return { submitForm: submit };
    },
    []
  );

  return (
    <div className="grid">
      <Row type={"flex"} justify={"center"}>
        <Col span={24}>
          <Form
            style={{ width: "100%" }}
            onSubmit={(values) => submitAnnouncementAdd(values)}
            getFormApi={(formApi) => (api.current = formApi)}
          >
            <Form.TextArea
              field="announcementContent"
              label="公告内容"
              style={{ width: "100%" }}
              maxCount={500}
              showClear
            ></Form.TextArea>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default React.forwardRef(AnnouncementAdd);
