import React from "react";
import { useNavigate, useParams, Link } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, screenSizeAtom } from "../../atom";
import { commonStyle } from "../../components/appCommon";

import { infoRow } from "../../components/appCommon";

import {
  Layout,
  Button,
  Form,
  Card,
  Row,
  Col,
  Table,
  Space,
  Dropdown,
  Typography,
  Toast,
  Descriptions,
  Tag,
  Modal,
} from "@douyinfe/semi-ui";

import AppHeader from "../../components/header";

// import { useForm, zodResolver } from "@mantine/form";
import z from "zod";

// const schema = z.object({
//   email: z.string().email({ message: "Invalid email" }),
// });

const emailSchema = z.string().email({ message: "Invalid email" });

const statusText = {
  0: "未支付",
  1: "已支付",
};

const rowWidth = commonStyle.tableRowWidth;
const pagerSize = commonStyle.pagerSize;

export default function UserEdit() {
  const { Title, Text } = Typography;

  const [jwtToken] = useAtom(jwtTokenAtom);
  const [screenSize, setScreenSize] = useAtom(screenSizeAtom);

  const navigate = useNavigate();

  const { id } = useParams();
  const [userInfo, setUserInfo] = React.useState({});
  const [orderPerPage, setorderPerPage] = React.useState(10);
  const [productPerPage, setproductPerPage] = React.useState(10);
  const [isChangePWD, setisChangePWD] = React.useState(true);
  const [visible, setVisible] = React.useState(false);
  const [userOrders, setUserOrders] = React.useState([]);
  const [userProducts, setUserProducts] = React.useState([]);
  const [plans, setPlans] = React.useState([]);

  const api = React.useRef();

  const { Content, Footer } = Layout;
  const { Column } = Table;

  const fetchPlans = () => {
    axios.get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/plans").then((planRes) => {
      axios.get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/users/detail/" + id).then((useRes) => {
        // console.log(planRes.data.data);
        // console.log(useRes.data.data.user);
        console.log(useRes.data.data.orders);
        console.log(useRes.data.data.products);

        setUserOrders(useRes.data.data.orders);
        setPlans(planRes.data.data);

        setUserInfo(useRes.data.data.user);
        setUserProducts(useRes.data.data.products);
      });
    });
  };

  const updateUser = (values) => {
    // const isEmail = schema.parse(values);

    console.log("enter updateUser");

    const isEmail = emailSchema.safeParse(values.email);

    if (!isEmail.success) {
      Toast.error({ content: "Invalid email", duration: 3 });
      return;
    }

    const putData = {
      email: values.email,
    };

    if (values.changePassword) {
      putData["password"] = values.password;
    }

    axios
      .post(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/users/" + id + "/edit", {
        email: values.email,
        password: values.password,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => {
        alert(e);
      });
  };

  React.useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col span={23}>
              <Row>
                <Card
                  title={"用户详情"}
                  headerExtraContent={
                    <Space>
                      <Text
                        link
                        onClick={() => {
                          setVisible(true);
                        }}
                      >
                        编辑
                      </Text>
                    </Space>
                  }
                >
                  <Space vertical style={{ width: "100%" }}>
                    {infoRow(
                      [
                        { text: "ID:", value: userInfo.id },
                        { text: "邮箱:", value: userInfo.email },
                      ],
                      screenSize
                    )}
                    {infoRow(
                      [
                        { text: "创建于:", value: userInfo.created_at },
                        {
                          text: "是否已删除:",
                          value: userInfo.is_deleted ? "是" : "否",
                        },
                      ],
                      screenSize
                    )}
                    {infoRow(
                      [
                        { text: "ios_udid:", value: userInfo.ios_udid },
                        { text: "电话:", value: userInfo.phone_no },
                      ],
                      screenSize
                    )}
                    {infoRow([{ text: "jti:", value: userInfo.jti }], screenSize)}
                  </Space>
                </Card>
              </Row>
              <Modal
                title="基本对话框"
                visible={visible}
                onOk={() => {
                  api.current.submitForm();
                  setVisible(false);
                }}
                onCancel={() => {
                  setVisible(false);
                }}
                style={{ width: 350 }}
                closeOnEsc={true}
              >
                <Form onSubmit={updateUser} getFormApi={(formApi) => (api.current = formApi)}>
                  <Form.Input field="email" label="邮箱" />
                  <Form.Switch
                    field="changePassword"
                    label="更改密码"
                    onChange={(v, e) => {
                      setisChangePWD(!v);
                    }}
                  />
                  <Form.Input field="password" label="密码" disabled={isChangePWD} />
                </Form>
              </Modal>
              <Space></Space>
              <Row>
                <Card title={"产品列表"}>
                  <Table
                    dataSource={userProducts}
                    pagination={{
                      pageSize: productPerPage,
                      total: userProducts.length,
                      size: pagerSize(screenSize),
                      hoverShowPageSelect: true,
                    }}
                    style={{ backgroundColor: "white" }}
                  >
                    <Column
                      title="ID"
                      dataIndex="id"
                      key="id"
                      fixed
                      width={rowWidth.short}
                      render={(id) => {
                        return (
                          <Button
                            onClick={() => {
                              navigate("/product/info/" + id);
                            }}
                          >
                            {id}
                          </Button>
                        );
                      }}
                    />
                    <Column title="创建时间" dataIndex="created_at" key="created_at" width={rowWidth.long} />
                    <Column title="过期时间" dataIndex="expiry_date" key="expiry_date" width={rowWidth.mid} />
                    <Column
                      title="套餐来源"
                      key="planFrom"
                      render={(order) => {
                        return order.ios_iap == 1 ? "iOS" : "网页";
                      }}
                      width={rowWidth.short}
                    />
                  </Table>
                </Card>
              </Row>
              <Space></Space>
              <Row>
                <Card
                  title={"用户订单"}
                  headerExtraContent={
                    <Space>
                      <Text
                        link
                        onClick={() => {
                          navigate("/order/add/" + id + "/-1");
                        }}
                      >
                        新增
                      </Text>
                    </Space>
                  }
                >
                  <Table
                    dataSource={userOrders}
                    pagination={{
                      pageSize: orderPerPage,
                      total: userOrders.length,
                      size: pagerSize(screenSize),
                      hoverShowPageSelect: true,
                    }}
                    style={{ backgroundColor: "white" }}
                  >
                    <Column title="ID" dataIndex="id" key="id" fixed width={rowWidth.short} />
                    <Column title="创建时间" dataIndex="created_at" key="created_at" width={rowWidth.mid} />
                    <Column
                      title="套餐"
                      key="plan"
                      render={(v) => {
                        const plan = plans.find((x) => x.id == v.plan);
                        if (plan === undefined) {
                          return "";
                        } else return plan.name;
                      }}
                      width={rowWidth.mid}
                    />
                    <Column title="价格" dataIndex="price" key="price" width={rowWidth.mid} />
                    <Column
                      title="状态"
                      key="status"
                      render={(order) => {
                        return statusText[order.status];
                      }}
                      width={rowWidth.short}
                    />
                  </Table>
                </Card>
              </Row>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
