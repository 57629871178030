import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom } from "../../atom";

import {
  Layout,
  Button,
  Row,
  Col,
  Table,
  Card,
  Modal,
  Space,
  Dropdown,
  Tag,
  Form,
  Typography,
} from "@douyinfe/semi-ui";

import AppHeader from "../../components/header";

export default function OrderAdd(props, ref) {
  const { Content, Footer } = Layout;
  const { Column } = Table;
  const { Text } = Typography;

  const [jwtToken] = useAtom(jwtTokenAtom);

  const navigate = useNavigate();

  const { user, plan } = useParams();
  console.log(user, " ", plan, new Date(Date.now()));
  React.useEffect(() => {}, []);

  const handleSubmit = (values) => {
    const date = values.due_date;
    console.log(date.toString());
    values.due_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    console.log(values);

    // return;

    axios
      .post(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/orders/add", values, {
        headers: {
          Authorization: jwtToken,
          "Content-Type": "multipart/form-data",
          processData: "false",
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          navigate("/order");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col sm={{ span: 24 }} md={{ span: 16 }} xl={{ span: 12 }} xxl={{ span: 8 }}>
              <Card title="新增订单" style={{ margin: "20px 0px" }}>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col span={11}>
                      <Form.InputNumber
                        field="user"
                        label="用户ID"
                        style={{ width: "100%" }}
                        hideButtons
                        initValue={user == -1 ? null : parseInt(user)}
                      />
                    </Col>
                    <Col offset={2} span={11}>
                      <Form.InputNumber
                        field="plan"
                        label="套餐ID"
                        style={{ width: "100%" }}
                        hideButtons
                        initValue={plan == -1 ? "" : plan}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.InputNumber field="product" label="产品ID" style={{ width: "100%" }} hideButtons />
                    </Col>
                    <Col>
                      <Form.InputNumber
                        field="price"
                        label="价格"
                        precision={2}
                        style={{ width: "100%" }}
                        hideButtons
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={11}>
                      <Form.Select field="status" label="状态" style={{ width: "100%" }}>
                        <Form.Select.Option value={0}>未支付</Form.Select.Option>
                        <Form.Select.Option value={1}>已支付</Form.Select.Option>
                      </Form.Select>
                    </Col>
                    <Col offset={2} span={11}>
                      <Form.Select field="billing_interval" label="付款周期" style={{ width: "100%" }}>
                        <Form.Select.Option value={1}>月付</Form.Select.Option>
                        <Form.Select.Option value={3}>季付</Form.Select.Option>
                        <Form.Select.Option value={6}>半年付</Form.Select.Option>
                        <Form.Select.Option value={12}>年付</Form.Select.Option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Form.DatePicker
                      field="due_date"
                      label="失效日期"
                      initValue={new Date(new Date().getTime() + 86400000 * 3)}
                      style={{ width: "100%" }}
                      placeholder="请选择生效日期"
                    />
                  </Row>
                  <Row type={"flex"} justify={"center"}>
                    <Button type="primary" htmlType="submit">
                      提交
                    </Button>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
