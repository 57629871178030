import React from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom } from "../../atom";

import { planSchema } from "./common";
import { deleteNullPorp } from "../../components/appCommon";

import {
  Layout,
  Toast,
  Button,
  Form,
  Card,
  Col,
  Row,
  useFormState,
} from "@douyinfe/semi-ui";

function PlanAdd(props, ref) {
  const { RadioGroup, Radio, Select } = Form;

  const [jwtToken] = useAtom(jwtTokenAtom);

  const handleSubmit = (values) => {
    console.log(values);

    let parseResult = planSchema.safeParse(values);

    console.log(parseResult);

    if (!parseResult.success) {
      values = deleteNullPorp(parseResult, values);

      parseResult = planSchema.safeParse(values);
      if (!parseResult.success) {
        return;
      }
    }

    axios
      .post(
        process.env.REACT_APP_API_ROUTE + "/api/v1/staff/plans/add",
        values,
        {
          headers: {
            Authorization: jwtToken,
            "Content-Type": "multipart/form-data",
            processData: "false",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          props.refresh();
        }
      });
  };

  const api = React.useRef();

  const submit = () => {
    api.current.submitForm();
  };

  React.useImperativeHandle(
    ref,
    () => {
      return { submitForm: submit };
    },
    []
  );

  return (
    <div className="grid">
      <Row type={"flex"} justify={"center"}>
        <Col>
          <Form
            onSubmit={(values) => handleSubmit(values)}
            getFormApi={(formApi) => (api.current = formApi)}
          >
            <Row>
              <Form.Input
                field="name"
                label="套餐名"
                style={{ width: "100%" }}
              ></Form.Input>
            </Row>

            <Row>
              <Form.Input
                field="description"
                label="套餐描述"
                style={{ width: "100%" }}
              ></Form.Input>
            </Row>

            <Row>
              <Col span={6}>
                <Form.Switch
                  field="hidden"
                  label="隐藏套餐"
                  initValue={false}
                  style={{ width: "50%" }}
                ></Form.Switch>
              </Col>
              <Col span={12} offset={6}>
                <Form.InputNumber field="level" label="等级" hideButtons />
              </Col>
            </Row>

            <Row>
              <Form.InputNumber field="traffic" label="套餐流量" hideButtons />
            </Row>
            <Row>
              <Col span={10}>
                <Form.InputNumber
                  field="monthly_price"
                  label="月付价格"
                  hideButtons
                  precision={2}
                />
              </Col>
              <Col span={10} offset={2}>
                <Form.InputNumber
                  label="季付价格"
                  hideButtons
                  precision={2}
                  field="quarterly_price"
                />
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Form.InputNumber
                  label="半年付价格"
                  hideButtons
                  precision={2}
                  field="semiannually_price"
                />
              </Col>
              <Col span={10} offset={2}>
                <Form.InputNumber
                  label="年付价格"
                  hideButtons
                  precision={2}
                  field="annually_price"
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default React.forwardRef(PlanAdd);
