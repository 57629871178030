import { React, useImperativeHandle, forwardRef, useRef } from "react";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom } from "../../atom";
import { nodeSchema } from "./common";

import { deleteNullPorp } from "../../components/appCommon";

import {
  Layout,
  Toast,
  Button,
  Form,
  Card,
  Col,
  Row,
  useFormState,
  Typography,
} from "@douyinfe/semi-ui";

function NodeAdd(props, ref) {
  const { Content } = Layout;
  const { Text } = Typography;

  const { RadioGroup, Radio, Select } = Form;

  const [jwtToken] = useAtom(jwtTokenAtom);

  const handleSubmit = (values) => {
    let data = values;

    console.log(data);
    let result = nodeSchema.safeParse(data);

    if (!result.success) {
      data = deleteNullPorp(result, data);

      result = nodeSchema.safeParse(data);
      if (!result.success) {
        console.log(result);

        result.error.issues.forEach((i) => {
          // Toast(i.path[0] + "is" + i.message, 0);
          alert(i.path[0] + " is " + i.message);
        });
        return;
      }
    }

    console.log(data);

    axios
      .post(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/nodes/add", data, {
        headers: {
          Authorization: jwtToken,
          "Content-Type": "multipart/form-data",
          processData: "false",
        },
      })
      .then((res) => {
        if (res.data.status === "0") {
          props.refresh();
        }
      });
  };

  const api = useRef();

  const submit = () => {
    api.current.submitForm();
  };

  useImperativeHandle(
    ref,
    () => {
      return { submitForm: submit };
    },
    []
  );

  return (
    <div className="grid">
      <Row type={"flex"} justify={"center"}>
        <Col>
          <Form
            onSubmit={(values) => handleSubmit(values)}
            getFormApi={(formApi) => (api.current = formApi)}
          >
            <Form.Input
              field="name"
              label="节点名"
              style={{ width: "100%" }}
              rules={[{ required: true, message: "必须填写节点名" }]}
            ></Form.Input>
            <Row type={"flex"}>
              <Col span={6}>
                <Form.InputNumber
                  field="level"
                  label="等级"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "必须填写等级" }]}
                  hideButtons
                  onChange={(v) => {
                    api.current.setValue("level", v);
                  }}
                />
              </Col>
              <Col offset={5} span={13}>
                <Select
                  field="sort"
                  label="类型"
                  placeholder="请选择类型"
                  rules={[{ required: true, message: "必须选择类型" }]}
                  style={{ width: "100%" }}
                >
                  <Select.Option value={1}>http </Select.Option>
                  <Select.Option value={2}>socks5 </Select.Option>
                  <Select.Option value={3}>shadowsocks </Select.Option>
                </Select>
              </Col>
            </Row>

            <Form.Input
              field="address"
              label="地址"
              rules={[{ required: true, message: "必须填写地址 " }]}
              style={{ width: "100%" }}
            ></Form.Input>

            <Form.InputNumber
              field="port"
              label="端口"
              style={{ width: "100%" }}
              hideButtons
              onChange={(v) => {
                api.current.setValue("port", v);
              }}
            />

            <Form.Input
              field="cipher"
              label="加密方式"
              style={{ width: "100%" }}
              rules={[{ required: true, message: "必须填写加密方式" }]}
            ></Form.Input>
            <Form.Input
              field="city"
              label="城市"
              style={{ width: "100%" }}
              rules={[{ required: true, message: "必须填写城市" }]}
            ></Form.Input>
            <Row type={"flex"} gutter={20}>
              <Col span={10}>
                <RadioGroup
                  field="relay"
                  label="是否中转"
                  initValue={false}
                  rules={[{ type: "boolean" }]}
                >
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </RadioGroup>
              </Col>
              <Col offset={2} span={12}>
                <Form.Input
                  field="relay_country"
                  label="中转城市"
                  style={{ width: "100%" }}
                ></Form.Input>
              </Col>
            </Row>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            ></div>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default forwardRef(NodeAdd);
