import { React, useImperativeHandle, forwardRef, useRef } from "react";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom } from "../../atom";

import {
  Layout,
  Toast,
  Button,
  Form,
  Card,
  Col,
  Row,
  Input,
  useFormState,
  Typography,
} from "@douyinfe/semi-ui";

function AnnouncementEdit(props, ref) {
  const { Content } = Layout;
  const { Text } = Typography;
  const api = useRef();

  const { RadioGroup, Radio, Select } = Form;

  const [jwtToken] = useAtom(jwtTokenAtom);

  //   api.current.setValue("name", node.name);

  const handleSubmit = (values) => {
    console.log(values, props.announce);

    axios
      .post(
        process.env.REACT_APP_API_ROUTE +
          "/api/v1/staff/announcements/" +
          props.announce.id +
          "/edit",
        values,
        {
          headers: {
            Authorization: jwtToken,
            "Content-Type": "multipart/form-data",
            processData: "false",
          },
        }
      )
      .then((res) => {
        if (res.data.code === 0) {
          props.refresh();
        } else {
          console.log(res.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const submit = () => {
    api.current.submitForm();
  };

  useImperativeHandle(
    ref,
    () => {
      return { submitForm: submit };
    },
    []
  );

  return (
    <div className="grid">
      <Form
        style={{ width: "100%" }}
        onSubmit={(values) => handleSubmit(values)}
        getFormApi={(formApi) => (api.current = formApi)}
      >
        <Form.TextArea
          initValue={props.announce.content}
          field="content"
          label="公告内容"
          style={{ width: "100%" }}
          maxCount={500}
          showClear
        ></Form.TextArea>
      </Form>
    </div>
  );
}

export default forwardRef(AnnouncementEdit);
