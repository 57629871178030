import React from "react";
import { useNavigate, Link } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, screenSizeAtom } from "../../atom";

import { commonStyle } from "../../components/appCommon";

// import { AppShell, Table, Title, Button, TextInput } from "@mantine/core";
import {
  Layout,
  Button,
  Table,
  Card,
  Row,
  Col,
  Space,
  Dropdown,
  Tag,
  Switch,
  Typography,
  Input,
} from "@douyinfe/semi-ui";
import { IconSearch } from "@douyinfe/semi-icons";

import AppHeader from "../../components/header";

import Fuse from "fuse.js";
import { Title } from "@douyinfe/semi-ui/lib/es/skeleton/item";

const fuseOption = {
  threshold: 0,
  ignoreLocation: true,
  keys: ["email"],
};

const tabelscroll = commonStyle.tabelscroll;
const rowWidth = commonStyle.tableRowWidth;

export default function User() {
  const pagerSize = commonStyle.pagerSize;

  const [screenSize, setScreenSize] = useAtom(screenSizeAtom);

  const [jwtToken] = useAtom(jwtTokenAtom);

  const navigate = useNavigate();

  const [perPage, setPerPage] = React.useState(10);

  const [fullList, setfullList] = React.useState([]);

  const [userList, setUserList] = React.useState([]);
  const [searchKey, setSearchKey] = React.useState([]);
  const [userSearchList, setUserSearchList] = React.useState([]);
  const [isSearching, setIsSearching] = React.useState(false);

  const [filtVisitor, setfiltVisitor] = React.useState(false);
  const [filtDeleted, setfiltDeleted] = React.useState(false);

  const { Content, Footer } = Layout;
  const { Column } = Table;
  const { Text, Title } = Typography;

  const filtUserList = () => {
    let List = fullList;

    console.log(filtDeleted, filtVisitor);

    if (filtDeleted) {
      List = List.filter((user) => !user.is_deleted);
    }

    if (filtVisitor) {
      List = List.filter((user) => user.ios_udid !== user.phone_no);
    }

    setUserList(List);
  };

  const fetchUserList = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/users?limit=-1")
      .then((res) => {
        console.log(res.data.data);

        setUserList(res.data.data);

        setfullList(res.data.data);

        setUserSearchList([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchInput = (v) => {
    const searchRes = fullList.filter((user) => {
      if (user.phone_no) {
        return user.phone_no.includes(v);
      } else {
        return user.email.includes(v);
      }
    });
    setUserList(searchRes);
  };

  React.useEffect(() => {
    fetchUserList();
  }, []);

  React.useEffect(() => {
    filtUserList();
  }, [filtDeleted, filtVisitor]);

  const renderEdit = (item) => {
    return (
      <Button
        onClick={() => {
          navigate("/user/edit/" + item.id);
        }}
      >
        查看
      </Button>
    );
  };

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col span={23}>
              <Card
                title={
                  <Row type="flex" align="middle" justify="space-between">
                    <Col>
                      <Title heading={6} style={{ margin: "0px" }}>
                        用户列表
                      </Title>
                    </Col>
                    <Col>
                      <Row type="flex" align="middle">
                        <Col>
                          <Input
                            placeholder={"搜索手机号"}
                            suffix={
                              <Button
                                icon={
                                  <IconSearch
                                    onClick={() => {
                                      searchInput(searchKey);
                                    }}
                                  />
                                }
                              ></Button>
                            }
                            style={{ width: "150px" }}
                            onChange={(v) => {
                              setSearchKey(v);
                            }}
                            showClear
                          ></Input>
                        </Col>
                        <Col>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Text style={{ margin: 8 }}>隐藏已删除</Text>
                            <Switch
                              onChange={async (e) => {
                                setfiltDeleted(e);
                              }}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Text style={{ margin: 8 }}>隐藏游客</Text>
                            <Switch
                              onChange={async (e) => {
                                console.log(e);
                                setfiltVisitor(e);
                              }}
                            />
                          </div>
                        </Col>
                        <Col>
                          <Dropdown
                            render={
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setPerPage(10)}>每页 10 条</Dropdown.Item>
                                <Dropdown.Item onClick={() => setPerPage(25)}>每页 25 条</Dropdown.Item>
                                <Dropdown.Item onClick={() => setPerPage(50)}>每页 50 条</Dropdown.Item>
                              </Dropdown.Menu>
                            }
                          >
                            <Tag>每页 {perPage} 条</Tag>
                          </Dropdown>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                }
              >
                <Table
                  dataSource={userList}
                  pagination={{
                    pageSize: perPage,
                    total: userList.length,
                    size: pagerSize(screenSize),
                    hoverShowPageSelect: true,
                  }}
                  style={{ backgroundColor: "white" }}
                  scroll={tabelscroll}
                >
                  <Column title="ID" dataIndex="id" key="id" fixed width={rowWidth.short} align="center" />
                  <Column title="邮箱" dataIndex="email" key="email" width={rowWidth.long} align="center" />
                  <Column title="手机号" dataIndex="phone_no" key="phone_no" width={rowWidth.long} align="center" />
                  <Column title="操作" key="operate" render={renderEdit} width={rowWidth.mid} align="center" />
                </Table>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
