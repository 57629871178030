import React from "react";
import { useNavigate, useParams, Link } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, screenSizeAtom } from "../../atom";
import { commonStyle, infoRow } from "../../components/appCommon";

import { renderText } from "./affCommon";

import {
  Layout,
  Button,
  Form,
  Card,
  Row,
  Col,
  Table,
  Space,
  Dropdown,
  Typography,
  Toast,
  Descriptions,
  Tag,
  Modal,
} from "@douyinfe/semi-ui";

const rowWidth = commonStyle.tableRowWidth;
const pagerSize = commonStyle.pagerSize;

export default function AffiliateInfo() {
  const { Title, Text } = Typography;

  const [jwtToken] = useAtom(jwtTokenAtom);
  const [screenSize, setScreenSize] = useAtom(screenSizeAtom);

  const navigate = useNavigate();

  const { id } = useParams();

  const [userAFFrecords, setuserAFFrecords] = React.useState([]);
  const [userAFFInfo, setuserAFFInfo] = React.useState({});
  const [perPage, setperPage] = React.useState(10);

  const api = React.useRef();

  const { Content, Footer } = Layout;
  const { Column } = Table;

  const fetchAff = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/affiliates/" + id)
      .then((res) => {
        if (res.data.code === 0) {
          console.log(res.data.data);
          setuserAFFInfo(res.data.data.affiliate);
          setuserAFFrecords(res.data.data.record);
        }
      });
  };

  React.useEffect(() => {
    fetchAff();
  }, []);

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col span={23}>
              <Row>
                <Card title={"返利详情"}>
                  <Space vertical style={{ width: "100%" }}>
                    {infoRow(
                      [
                        {
                          text: "返利ID：",
                          value: userAFFInfo.id,
                        },
                        {
                          text: "用户ID：",
                          value: userAFFInfo.user,
                        },
                      ],
                      screenSize
                    )}
                    {infoRow(
                      [
                        {
                          text: "模式：",
                          value: renderText.modeText[userAFFInfo.mode],
                        },
                        {
                          text: "状态：",
                          value: renderText.statusText[userAFFInfo.status],
                        },
                      ],
                      screenSize
                    )}
                    {infoRow(
                      [
                        {
                          text: "创建于：",
                          value: userAFFInfo.created_at,
                        },
                        {
                          text: "更新于：",
                          value: userAFFInfo.updated_at,
                        },
                      ],
                      screenSize
                    )}
                    {infoRow(
                      [
                        {
                          text: "返利比率：",
                          value: userAFFInfo.value + "%",
                        },
                        {
                          text: "备注：",
                          value: userAFFInfo.comment,
                        },
                      ],
                      screenSize
                    )}
                  </Space>
                </Card>
              </Row>

              <Space></Space>
              <Row>
                <Card title={"返利记录"}>
                  <Table
                    dataSource={userAFFrecords}
                    pagination={{
                      pageSize: perPage,
                      total: userAFFrecords.length,
                      size: pagerSize(screenSize),
                      hoverShowPageSelect: true,
                    }}
                    style={{ backgroundColor: "white" }}
                  >
                    <Column
                      title="ID"
                      dataIndex="id"
                      key="id"
                      fixed
                      width={rowWidth.short}
                      render={(id) => {
                        return (
                          <Button
                            onClick={() => {
                              navigate("/product/info/" + id);
                            }}
                          >
                            {id}
                          </Button>
                        );
                      }}
                    />
                    <Column
                      title="创建时间"
                      dataIndex="created_at"
                      key="created_at"
                      width={rowWidth.long}
                    />
                    <Column
                      title="订单ID"
                      dataIndex="order"
                      key="order"
                      width={rowWidth.mid}
                    />
                    <Column
                      title="返利金额"
                      dataIndex="amount"
                      key="amount"
                      
                      width={rowWidth.mid}
                    />
                     <Column
                      title="是否已提现"
                      dataIndex="payout_status"
                      key="payout_status"
                      render={(v)=>{
                        return v == 1 ? "是" : "否"
                      }}
                      width={rowWidth.mid}
                    />
                    <Column
                      title="套餐来源"
                      key="planFrom"
                      render={(order) => {
                        return order.ios_iap == 1 ? "iOS" : "网页";
                      }}
                      width={rowWidth.short}
                    />
                  </Table>
                </Card>
              </Row>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
