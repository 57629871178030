import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, screenSizeAtom } from "../../atom";

import {
  Layout,
  Button,
  Table,
  Card,
  Select,
  Typography,
  Row,
  Col,
  Space,
  Dropdown,
  Tag,
  Descriptions,
} from "@douyinfe/semi-ui";

import { infoRow } from "../../components/appCommon";

export default function ProductInfo() {
  const [product, setProduct] = React.useState({});
  const { productHidden, setProductHidden } = React.useState(false);

  const [screenSize] = useAtom(screenSizeAtom);

  const navigate = useNavigate();
  const headerRef = React.useRef();

  const { id } = useParams();
  console.log(id);

  const { Text } = Typography;
  const { Content } = Layout;
  const { Column } = Table;

  const [jwtToken] = useAtom(jwtTokenAtom);

  const fetchProduct = () => {
    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/staff/products/" + id, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        const product = res.data.data;
        setProduct(product);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchPlans = () => {
    axios
      .get(
        process.env.REACT_APP_API_ROUTE + "/api/v1/staff/products?limit=-1",
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    fetchProduct();
    // fetchProduct();
  }, []);

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row type={"flex"} justify={"center"}>
            <Col span={23}>
              <Card
                title={"产品详情：" + id}
                headerExtraContent={
                  <Space>
                    <Text
                      link
                      onClick={() => {
                        navigate("/order/add/" + product.user + "/-1");
                      }}
                    >
                      新增订单
                    </Text>
                  </Space>
                }
                style={{
                  margin: "20px 0px",
                }}
              >
                <Space vertical style={{ width: "100%" }}>
                  {infoRow(
                    [
                      { text: "产品ID", value: product.id },
                      { text: "用户ID", value: product.user },
                    ],
                    screenSize
                  )}
                  {infoRow(
                    [
                      { text: "订单ID", value: product.order },
                      { text: "等级", value: product.level },
                    ],
                    screenSize
                  )}
                  {infoRow(
                    [
                      { text: "端口", value: product.port },
                      { text: "密码", value: product.password },
                    ],
                    screenSize
                  )}
                  {infoRow(
                    [
                      { text: "是否隐藏", value: product.hidden ? "是" : "否" },
                      { text: "付款周期", value: product.billing_interval },
                    ],
                    screenSize
                  )}
                  {infoRow(
                    [
                      { text: "过期时间", value: product.expiry_date },
                      { text: "价格", value: product.price },
                    ],
                    screenSize
                  )}
                  {infoRow(
                    [
                      { text: "状态", value: product.status },
                      { text: "总共流量", value: product.traffic_total },
                    ],
                    screenSize
                  )}
                  {infoRow(
                    [{ text: "剩余流量", value: product.traffic_remaining }],
                    screenSize
                  )}
                </Space>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
