import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom } from "../../atom";

import { planEditSchema } from "./common";
import { deleteNullPorp } from "../../components/appCommon";

import {
  Layout,
  Toast,
  Button,
  Form,
  Card,
  Col,
  Row,
  Switch,
  useFormState,
  Typography,
  Space,
} from "@douyinfe/semi-ui";
import Plan from ".";

function PlanEdit(props, ref) {
  const { Text } = Typography;

  const plan = props.plan;

  const [jwtToken] = useAtom(jwtTokenAtom);

  const submitPlanEdit = (values) => {
    let parseResult = planEditSchema.safeParse(values);

    if (values.hidden === plan.hidden) {
      Reflect.deleteProperty(values, "hidden");
    }
    console.log(values);
    // console.log(values, parseResult);
    if (!parseResult.success) {
      values = deleteNullPorp(parseResult, values);

      parseResult = planEditSchema.safeParse(values);
      if (!parseResult.success) {
        return;
      }
    }

    axios
      .post(
        process.env.REACT_APP_API_ROUTE +
          "/api/v1/staff/plans/" +
          plan.id +
          "/edit",
        values,
        {
          headers: {
            Authorization: jwtToken,
            "Content-Type": "multipart/form-data",
            processData: "false",
          },
        }
      )
      .then((res) => {
        if (res.data.code === 0) {
          props.refresh();
        }
      });
  };

  // React.useEffect(() => {
  //   fetchPlanEdit();
  // }, []);

  const api = React.useRef();

  const submit = () => {
    api.current.submitForm();
  };

  React.useImperativeHandle(
    ref,
    () => {
      return { submitForm: submit };
    },
    []
  );

  return (
    <div className="grid">
      <Row type={"flex"} justify={"center"}>
        <Col>
          <Form
            onSubmit={(values) => submitPlanEdit(values)}
            getFormApi={(formApi) => (api.current = formApi)}
          >
            <Row>
              <Form.Input
                field="name"
                label="套餐名"
                style={{ width: "100%" }}
                placeholder={plan.name}
              ></Form.Input>
            </Row>

            <Row>
              <Form.Input
                field="description"
                label="套餐描述"
                style={{ width: "100%" }}
                placeholder={plan.description}
              ></Form.Input>
            </Row>

            <Row type="flex" align="middle">
              <Col span={6}>
                <Text style={{ fontWeight: "bold" }}>隐藏套餐</Text>
                <Switch
                  field="hidden"
                  label="隐藏套餐"
                  style={{ width: "50%" }}
                  defaultChecked={plan.hidden}
                  onChange={(v) => {
                    api.current.setValue("hidden", v);
                  }}
                ></Switch>
              </Col>
              <Col span={12} offset={6}>
                <Form.InputNumber
                  field="level"
                  label="等级"
                  hideButtons
                  placeholder={plan.level}
                  onChange={(v) => {
                    api.current.setValue("level", v);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Form.InputNumber
                field="traffic"
                label="套餐流量"
                hideButtons
                placeholder={plan.traffic}
                onChange={(v) => {
                  api.current.setValue("traffic", v);
                }}
              />
            </Row>
            <Row>
              <Col span={11}>
                <Form.InputNumber
                  field="monthly_price"
                  label="月付价格"
                  hideButtons
                  precision={2}
                  placeholder={Number(plan.monthly_price)}
                  // onChange={(v) => {
                  //   api.current.setValue("monthly_price", v);
                  // }}
                />
              </Col>
              <Col span={11} offset={2}>
                <Form.InputNumber
                  label="季付价格"
                  hideButtons
                  precision={2}
                  field="quarterly_price"
                  placeholder={Number(plan.quarterly_price)}
                  // onChange={(v) => {
                  //   api.current.setValue("quarterly_price", v);
                  // }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.InputNumber
                  label="半年付价格"
                  hideButtons
                  precision={2}
                  field="semiannually_price"
                  placeholder={Number(plan.semiannually_price)}
                  // onChange={(v) => {
                  //   api.current.setValue("semiannually_price", v);
                  // }}
                />
              </Col>
              <Col span={11} offset={2}>
                <Form.InputNumber
                  label="年付价格"
                  hideButtons
                  precision={2}
                  field="annually_price"
                  placeholder={Number(plan.annually_price)}
                  // onChange={(v) => {
                  //   api.current.setValue("annually_price", v);
                  // }}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default React.forwardRef(PlanEdit);
