import z from "zod";

export const affSchema = z.object({
  user: z.number().int(),
  mode: z.number().int(),
  status: z.number().int(),
  value: z.number().int(),
  code: z.string().optional(),
  comment: z.string().optional(),
});

export const renderText = {
  modeText: {
    0: "首单",
    1: "循环",
  },
  statusText: {
    0: "已暂停",
    1: "已激活",
  },
};
